/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import {
  IConfigArea,
  IConfigSegmento,
  IConfigSubArea,
  IFaixaSalarial,
  IIdioma,
  INivelDeCargo,
  ISetor,
  IUsuario,
} from '../../../../commons/genericTypes';
import {
  EmpresaStatusEnum,
  IEmpresaRelacionamento,
  IOrigemVaga,
  IVaga,
} from '../../../../commons/genericTypes/mercadoAlvo';
import { ButtonStato, SelectStato } from '../../../../commons/styleds';
import MultiSelect from '../../../../components/Generics/MultiSelect';
import MultiSelectNome from '../../../../components/Generics/MultiSelectNome';
import { history } from '../../../../helpers/history';
import { useUsuarioActions } from '../../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { useConfigAreaActions } from '../../../../states/config/ConfiguracaoSistema/areas/configArea.actions';
import { useFaixaSalarialActions } from '../../../../states/config/ConfiguracaoSistema/faixasSalariais/faixaSalarial.actions';
import { useIdiomaConfigActions } from '../../../../states/config/ConfiguracaoSistema/idiomas/idioma.actions';
import { useNivelDeCargoActions } from '../../../../states/config/ConfiguracaoSistema/niveisDeCargos/nivelDeCargo.actions';
import { useConfigSegmentoActions } from '../../../../states/config/ConfiguracaoSistema/segmentos/configSegmento.actions';
import { useSetorActions } from '../../../../states/config/ConfiguracaoSistema/setores/setor.actions';
import { useConfigSubAreaActions } from '../../../../states/config/ConfiguracaoSistema/subAreas/configSubArea.actions';
import { useEmpresaRelacionamentoActions } from '../../../../states/mesaDeMercado/empresaRelacionamento/empresaRelacionamento.actions';
import { useOrigemVagasActions } from '../../../../states/mesaDeMercado/origemVagas/origemVagas.actions';
import { useVagaActions } from '../../../../states/mesaDeMercado/vagas/vaga.actions';
import { Container } from '../../../CRM/Empresa';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../../CRM/MiniBio/accordionAndTabPanel';
import { IParams } from '../../../CRM/MiniBio/Endereco';
import LocalVaga from './LocalVaga';
import { useGrupoActions } from '../../../../states/grupos/grupo.actions';

const empty: IVaga = {
  id: undefined,
  titulo: '',
  descricao: '',
  infoConfidencial: '',
  emailRecrutador: '',
  cidade: '',
  estado: '',
  pais: '',
  dataInicio: null,
  dataTermino: null,
  ativo: false,
  dtaCadastro: new Date(),
  vagaRelacionamento: '',
  idOrigem: 0,
  meioCaptacao: undefined,
  ma: '',
  contato: '',
  status: undefined,
  usuarioCadastro: undefined,

  idResponsavel: 0,
  idFaixaSalarial: 0,
  idEmpresaRelacionamento: 0,

  setores: [],
  areas: [],
  subAreas: [],
  segmentos: [],
  idiomas: [],
  niveisCargos: [],

  ogirem: { id: undefined, origem: '' },
  responsavel: { id: undefined, nome: '' } as any,
};

const InformacoesVaga: React.FC = () => {
  const { id } = useParams<IParams>();
  const novoCadastro = id === 'nova';
  const methods = useForm<IVaga>({ defaultValues: empty });
  const [loading, setLoading] = useState<boolean>(true);

  const vagaActions = useVagaActions();

  const origensActions = useOrigemVagasActions();
  const [origens, setOrigens] = useState<IOrigemVaga[]>([]);

  const gruposActions = useGrupoActions();
  const usuariosActions = useUsuarioActions();
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);

  const faixaSalarialActions = useFaixaSalarialActions();
  const [faixaSalariais, setFaixaSalariais] = useState<IFaixaSalarial[]>([]);

  const idiomasActions = useIdiomaConfigActions();
  const [idiomas, setIdiomas] = useState<IIdioma[] | undefined>(undefined);

  const nivelDeCargoActions = useNivelDeCargoActions();
  const [niveisDeCargos, setNiveisDeCargos] = useState<INivelDeCargo[]>([]);

  const configAreaActions = useConfigAreaActions();
  const [areas, setAreas] = useState<IConfigArea[]>([]);

  const configSubAreaActions = useConfigSubAreaActions();
  const [subAreas, setSubAreas] = useState<IConfigSubArea[] | undefined>(undefined);

  const configSegmentoActions = useConfigSegmentoActions();
  const [segmentos, setSegmento] = useState<IConfigSegmento[] | undefined>(undefined);

  const configSetorActions = useSetorActions();
  const [setores, setSetor] = useState<ISetor[] | undefined>(undefined);
  const [setoresFiltrados, setSetoresFiltrados] = useState<ISetor[]>([]);

  const empresasActions = useEmpresaRelacionamentoActions();
  const [empresas, setEmpresas] = useState<IEmpresaRelacionamento[] | undefined>(undefined);

  const status = methods.watch('status');
  const area = methods.watch('areas');
  const subArea = methods.watch('subAreas');
  const segmentosSelecionados = methods.watch('segmentos');
  const setor = methods.watch('setores');

  const carregarEmpresas = () => {
    empresasActions
      .list(false, `?pagina=${1}&tamanhoPagina=${15000}`)
      .then((data) => setEmpresas(data.itens));
  };

  const loadUsuarios = () => {
    gruposActions
      .list()
      .then((resp: any) => {
        const grupo = resp.find((g: any) => g.nome == 'Mesa de Mercado');
        usuariosActions
          .buscarUsuarios(false, grupo?.id)
          .then((resp: any) => setUsuarios(resp.resultado))
          .catch((err: any) => toast.warn(err));
      });
  }

  async function fetchData() {
    try {
      carregarEmpresas();
      const areaPromise = configAreaActions.list();
      const subAreaPromise = configSubAreaActions.list();
      const segmentoPromise = configSegmentoActions.list();
      const faixaSalarialPromise = faixaSalarialActions.list();
      const setorPromise = configSetorActions.list();
      const nivelDeCargoPromise = nivelDeCargoActions.list();
      const idiomasPromise = idiomasActions.list();
      const origensPromise = origensActions.list();
      loadUsuarios();

      const [
        areas,
        subAreas,
        segmentos,
        faixasSalariais,
        setores,
        niveisDeCargos,
        idiomas,
        origens,
      ] = await Promise.all([
        areaPromise,
        subAreaPromise,
        segmentoPromise,
        faixaSalarialPromise,
        setorPromise,
        nivelDeCargoPromise,
        idiomasPromise,
        origensPromise,
      ]);

      setOrigens(origens);
      setAreas(areas);
      setSubAreas(subAreas);
      setSegmento(segmentos);
      setFaixaSalariais(faixasSalariais);
      setSetor(setores);
      setSetoresFiltrados(setores);
      setNiveisDeCargos(niveisDeCargos);
      setIdiomas(idiomas);
    } catch (err: any) {
      toast.warn('Erro ao carregar os dados: ' + err.message);
    } finally {
      setLoading(false);
    }
  }

  const setMultiSelects = (data: any) => {
    data.areas = data.areas?.map((area: any) => area.id) ?? [];
    data.subAreas = data.subAreas?.map((subArea: any) => subArea.id) ?? [];
    data.segmentos = data.segmentos?.map((segmento: any) => segmento.id) ?? [];
    data.setores = data.setores?.map((setor: any) => setor.id) ?? [];
    data.niveisCargos = data.niveisCargos?.map((cargo: any) => cargo.id) ?? [];
    data.idiomas = data.idiomas?.map((idioma: any) => idioma.id) ?? [];
    return data;
  };

  useEffect(() => {
    const loadData = async () => {
      if (!novoCadastro) {
        let data = await vagaActions.get(Number(id)!);
        await fetchData();
        data = setMultiSelects(data);
        methods.reset(data);
      } else fetchData();
    }
    loadData();
  }, [id]);

  useEffect(() => {
    if (!area || area.length <= 0) return;

    const areasSelecionadas = areas?.filter((f) => area.includes(f.id as any));
    const subAreasComDuplicatas = areasSelecionadas?.flatMap((area) => area.idSubAreas);
    const subAreasUnicas = subAreasComDuplicatas ?
      Array.from(new Set(subAreasComDuplicatas.map(item => item?.id)))
        .map(id => subAreasComDuplicatas.find(item => item?.id === id))
        .filter(s => s != undefined) as IConfigSubArea[] :
      [];
    setSubAreas(subAreasUnicas);
    const novasSubAreasSelecionadas = subAreasUnicas?.filter((f) => subArea?.includes(f.id as any));
    methods.setValue('subAreas', novasSubAreasSelecionadas?.map((m: any) => m.id));
  }, [area]);

  useEffect(() => {
    if (!segmentosSelecionados || segmentosSelecionados.length <= 0) return;
    const setoresFiltradosNew = setores?.filter((f) => segmentosSelecionados.includes(f.idSegmento as any)) ?? [];
    setSetoresFiltrados(setoresFiltradosNew);
    const novosSetoresSelecionados = setoresFiltradosNew?.filter((f) => setor?.includes(f.id as any));
    methods.setValue('setores', novosSetoresSelecionados?.map((m: any) => m.id));
  }, [segmentosSelecionados]);

  const fillMultiSelects = (data: any) => {
    data.areas = areas?.filter((a) => data.areas?.includes(a.id));
    data.subAreas = subAreas?.filter((s) => data.subAreas?.includes(s.id));
    data.segmentos = segmentos?.filter((s) => data.segmentos?.includes(s.id));
    data.setores = setores?.filter((s) => data.setores?.includes(s.id));
    data.niveisCargos = niveisDeCargos?.filter((cargo) => data.niveisCargos?.includes(cargo.id));
    data.idiomas = idiomas?.filter((idioma) => data.idiomas?.includes(idioma.id));
    return data;
  };

  const onSubmit = async (data: IVaga) => {
    const idVaga = Number(id!);
    data = fillMultiSelects(data);

    try {
      if (novoCadastro) await vagaActions.create(data);
      else await vagaActions.update(idVaga, data);
      toast.success('Vaga salva com sucesso');
      history.goBack();
    } catch (error) {
      toast.error('Erro ao salvar vaga');
    }
  };

  return (
    <>
      <Container container rowSpacing={2} style={{ paddingTop: '0' }}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {novoCadastro ? 'Cadastrar nova Vaga' : 'Editar Vaga'}
          </Typography>
          <Divider sx={{ maxWidth: '40%' }} />
        </Grid>

        {loading && <LinearProgress />}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%' }}>
              <Accordion defaultExpanded>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Informações da Vaga</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={1} sx={{ marginTop: '10px' }}>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        name="titulo"
                        control={methods.control}
                        render={({ field }) => (
                          <TextField {...field} label="Título da Vaga" fullWidth />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="descricao"
                        control={methods.control}
                        render={({ field }) => (
                          <TextField 
                            {...field}
                            label="Descrição" 
                            fullWidth
                            multiline 
                            rows={4}
                            helperText={`${field.value?.length ?? 0}/100.000`}
                            slotProps={
                              { 
                                htmlInput: { 
                                  maxLength: 100000 
                                } 
                              }
                            }                          
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                      <Controller
                        name="dataInicio"
                        control={methods.control}
                        render={({ field }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={{
                              clearButtonLabel: 'Empty',
                              todayButtonLabel: 'Now',
                            }}
                          >
                            <DesktopDatePicker
                              {...field}
                              value={field.value ? dayjs(field.value) : undefined}
                              label="Data Início"
                              format="DD/MM/YYYY"
                              slotProps={{
                                textField: {
                                  required: true,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name="dataTermino"
                          control={methods.control}
                          render={({ field }) => (
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              localeText={{
                                clearButtonLabel: 'Empty',
                                todayButtonLabel: 'Now',
                              }}
                            >
                              <DesktopDatePicker
                                {...field}
                                value={field.value ? dayjs(field.value) : undefined}
                                label="Data Término"
                                format="DD/MM/YYYY"
                                slotProps={{
                                  textField: {
                                    required: true,
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Controller
                        name="infoConfidencial"
                        control={methods.control}
                        render={({ field }) => (
                          <TextField {...field} label="Inf. Confidencial" fullWidth />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Controller
                        name="idOrigem"
                        control={methods.control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id="origem-label">Origem</InputLabel>
                            <Select {...field} label="Origem">
                              {origens.map((origem, index) => (
                                <MenuItem value={origem.id} key={index}>
                                  {origem.origem}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {(novoCadastro || status) && (
                        <Controller
                          name="status"
                          control={methods.control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel id="status-label">Status</InputLabel>
                              <SelectStato {...field} required label="Status">
                                <MenuItem value={undefined}>Selecione</MenuItem>
                                {Object.values(EmpresaStatusEnum)?.map(
                                  (status: string, index: number) => (
                                    <MenuItem value={status} key={index}>
                                      {status}
                                    </MenuItem>
                                  ),
                                )}
                              </SelectStato>
                            </FormControl>
                          )}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Controller
                        name="contato"
                        control={methods.control}
                        render={({ field }) => <TextField {...field} label="Contato" fullWidth />}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Localidade da Vaga</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <LocalVaga />
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Detalhamento da Vaga</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={1} sx={{ marginTop: '10px' }}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="idResponsavel"
                        control={methods.control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id="agente-label">Agente de Mercado</InputLabel>
                            <SelectStato {...field} label="Agente de Mercado">
                              {usuarios?.map((usuario, index) => (
                                <MenuItem value={usuario.id} key={index}>
                                  {usuario.nome}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <Box display="flex" alignItems="center">
                        <Controller
                          name="idEmpresaRelacionamento"
                          control={methods.control}
                          render={({ field }) => (
                            <FormControl sx={{ flexGrow: 1 }}>
                              <InputLabel id="empresa-label">Empresa</InputLabel>
                              <SelectStato {...field} label="Empresa">
                                {empresas?.map((empresa, index) => (
                                  <MenuItem value={empresa.id} key={index}>
                                    {empresa.empresa}
                                  </MenuItem>
                                ))}
                              </SelectStato>
                            </FormControl>
                          )}
                        />
                        <Tooltip title="Carregar Empresas">
                          <IconButton onClick={carregarEmpresas} sx={{ ml: 1 }}>
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Adicionar Nova">
                          <IconButton
                            component="a"
                            href={`${window.location.origin}/empresas-relacionamento`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ ml: 1 }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="areas"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {areas && (
                              <MultiSelect
                                opcoes={(areas as IConfigArea[]) ?? []}
                                setValor={onChange}
                                valor={value || []}
                                label="Áreas"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="subAreas"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {subAreas && (
                              <MultiSelect
                                opcoes={(subAreas as IConfigSubArea[]) ?? []}
                                setValor={onChange}
                                valor={value || []}
                                label="Subareas"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="segmentos"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {segmentos && (
                              <MultiSelect
                                opcoes={segmentos ?? []}
                                setValor={onChange}
                                valor={value || []}
                                label="Segmentos"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="setores"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {setoresFiltrados && (
                              <MultiSelect
                                opcoes={(setoresFiltrados as ISetor[]) ?? []}
                                setValor={onChange}
                                valor={value || []}
                                label="Setores"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="idiomas"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {idiomas && (
                              <MultiSelectNome
                                opcoes={idiomas ?? []}
                                setValor={onChange}
                                valor={value || []}
                                label="Idiomas"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="niveisCargos"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {niveisDeCargos && (
                              <MultiSelect
                                opcoes={niveisDeCargos ?? []}
                                setValor={onChange}
                                valor={value || []}
                                label="Níveis de Cargo"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        name="idFaixaSalarial"
                        control={methods.control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id="faixa-salarial-label">Faixa Salarial</InputLabel>
                            <SelectStato {...field} label="Faixa Salarial">
                              {faixaSalariais?.map((fs, index) => (
                                <MenuItem value={fs.id} key={index}>
                                  {fs.nome}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <DivButton>
              <ButtonStato variant="contained" type="submit">
                Salvar
              </ButtonStato>
            </DivButton>
          </form>
        </FormProvider>
      </Container>
    </>
  );
};

const DivButton = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 25px;
`;

export default InformacoesVaga;
