import { useState, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { Autocomplete, Box, Button, Grid2 } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridActionsCellItem, GridRowEditStopReasons, GridRowModes, GridToolbarContainer, type GridColDef, type GridEventListener, type GridRowId, type GridRowModel, type GridRowModesModel, type GridRowsProp, type GridSlots } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { IMercadoAlvoRegiao, TipoRegiao } from '../../../../commons/genericTypes';
import { useFormContext } from 'react-hook-form';

interface ITableRegiao {
  regioes: IMercadoAlvoRegiao[];
  tipo: TipoRegiao;
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
  tipo: TipoRegiao;
}

const randomId = () => Math.floor(Math.random() * 9999) + 1;

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel, tipo } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        pais: '',
        estado: '',
        cidade: '',
        tipo: tipo,
        novo: true
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'pais' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Indicar Novo local de {tipo == TipoRegiao.DISPONIBILIDADE ? 'Disponibilidade' : 'Restrição'}
      </Button>
    </GridToolbarContainer>
  );
}

const TableRegiao = ({ regioes, tipo }: ITableRegiao) => {
  const { getValues, setValue } = useFormContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [regioesFiltradas, setRegioesFiltradas] = useState<IMercadoAlvoRegiao[]>([]);
  const [paises, setPaises] = useState<string[]>([]);
  const [estados, setEstados] = useState<{ id: number; nome: string }[]>([]);
  const [cidades, setCidades] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [rows, setRows] = useState<IMercadoAlvoRegiao[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selectedPais, setSelectedPais] = useState<string>('');
  const [selectedEstado, setSelectedEstado] = useState<string>('');

  useEffect(() => {
    const regioesFiltradas = regioes.filter((regiao) => regiao.tipo === tipo);
    setRegioesFiltradas(regioesFiltradas);
    setRows(regioesFiltradas);
  }, [regioes, tipo]);

  useEffect(() => {
    axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/paises?orderBy=nome')
      .then((response) => {
        const nomesPaises = response.data.map((pais: any) => pais.nome);
        setPaises(nomesPaises);
      })
      .catch((error) => console.error('Error fetching countries', error));
  }, []);

  useEffect(() => {
    if (selectedPais === 'Brasil') {
      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
        .then((response) => {
          setEstados(response.data);
        })
        .catch((error) => console.error('Error fetching states', error));
    } else {
      setEstados([]);
      setCidades([]);
    }
  }, [selectedPais]);

  useEffect(() => {
    if (selectedEstado && selectedPais === 'Brasil') {
      const estadoSelecionado = estados.find(
        (estado) => estado.nome.toLowerCase() === selectedEstado.toLowerCase(),
      );
      if (estadoSelecionado) {
        const estadoId = estadoSelecionado.id;
        axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios?orderBy=nome`,
          )
          .then((response) => {
            const nomesCidades = response.data.map((cidade: any) => cidade.nome);
            setCidades(nomesCidades);
          })
          .catch((error) => console.error('Error fetching cities', error));
      }
    } else {
      setCidades([]);
    }
  }, [selectedEstado, selectedPais, estados]);

  const updateFormRegioes = (updatedRows: IMercadoAlvoRegiao[]) => {
    const currentRegioes = getValues('mercadoAlvoRegioes') || [];
    const otherRegioes = currentRegioes.filter((regiao: IMercadoAlvoRegiao) => regiao.tipo !== tipo);
    const newRegioes = [...otherRegioes, ...updatedRows];
    setValue('mercadoAlvoRegioes', newRegioes, { shouldDirty: true });
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const searchOnEdit = (id: GridRowId) => {
    const row = rows.find(r => r.id === Number(id));
    if (!row) return;
    setSelectedPais(row.pais);
    setSelectedEstado(row.estado);

    if (row.pais != 'Brasil') return;
    axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
      .then((response) => {
        setEstados(response.data);

        if (row.estado) {
          const estadoSelecionado = response.data.find(
            (estado: any) => estado.nome.toLowerCase() === row.estado.toLowerCase(),
          );

          if (estadoSelecionado) {
            axios
              .get(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSelecionado.id}/municipios?orderBy=nome`,
              )
              .then((response) => {
                const nomesCidades = response.data.map((cidade: any) => cidade.nome);
                setCidades(nomesCidades);
              })
              .catch((error) => console.error('Error fetching cities', error));
          }
        }
      })
      .catch((error) => console.error('Error fetching states', error));
  }

  const handleEditClick = (id: GridRowId) => () => {
    searchOnEdit(id);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedRows = rows.filter((row) => row.id?.toString() !== id.toString());

    setRows(updatedRows);
    updateFormRegioes(updatedRows);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id?.toString() === id.toString());
    if (!editedRow!.id || editedRow!.id <= 0) {
      const updatedRows = rows.filter((row) => row.id?.toString() !== id.toString());
      setRows(updatedRows);
      updateFormRegioes(updatedRows);
    }
  };

  const processRowUpdate = async (newRow: GridRowModel<IMercadoAlvoRegiao>) => {
    setIsSaving(true);

    const updatedRow = {
      ...newRow,
      tipo,
      idMercadoAlvo: getValues('id') || 0
    };

    const updatedRows = rows.map((row) =>
      row.id === updatedRow.id ? updatedRow : row
    );

    setRows(updatedRows);
    updateFormRegioes(updatedRows);

    setIsSaving(false);
    toast.success('Salvo com sucesso!');
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: 'pais',
      headerName: 'País',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          fullWidth
          options={paises}
          value={params.value}
          onChange={(_, newValue) => {
            params.api.setEditCellValue({ id: params.id, field: 'pais', value: newValue });
            setSelectedPais(newValue || '');
            setSelectedEstado('');
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      ),
    },
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          fullWidth
          options={estados.map(estado => estado.nome)}
          value={params.value}
          onChange={(_, newValue) => {
            params.api.setEditCellValue({ id: params.id, field: 'estado', value: newValue });
            setSelectedEstado(newValue || '');
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
          disabled={!params.row.pais}
        />
      ),
    },
    {
      field: 'cidade',
      headerName: 'Cidade',
      flex: 1,
      editable: true,
      renderEditCell: (params) => (
        <Autocomplete
          fullWidth
          options={cidades}
          value={params.value}
          onChange={(_, newValue) => {
            params.api.setEditCellValue({ id: params.id, field: 'cidade', value: newValue });
          }}
          renderInput={(params) => <TextField {...params} size="small" />}
          disabled={!params.row.estado}
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 90,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              disabled={isSaving}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className="textPrimary"
              onClick={handleDeleteClick(id)}
              color="inherit"
              disabled={isSaving}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              disabled={isSaving}
            />
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ];
      },
    },
  ];

  return (
    <Grid2 
      size={{ xs: 12, sm: 12, md: 12 }} 
      rowSpacing={2} 
      columnSpacing={1} 
      sx={{ marginTop: '10px', width: '100% !important' }}
    >
      <Box
        sx={{
          height: 250,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => `${row.id}`}
          loading={loading}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          hideFooter
          slots={{
            toolbar: EditToolbar as GridSlots['toolbar'],
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel, tipo },
          }}
        />
      </Box>
    </Grid2>
  );
};

export default TableRegiao;
