import { useBaseActions } from '../../../base.actions';
import { eventoParticipanteAtom } from './EventoParticipanteState';

function useEventoParticipanteActions() {
  const baseActions = useBaseActions('EventoParticipantes', eventoParticipanteAtom);

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
  };
}

export { useEventoParticipanteActions };
