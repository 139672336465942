import HeaderPages from '../../components/Generics/HeaderPages';
import { GridItem, IconStato } from '../../commons/styleds';
import { history } from '../../helpers/history';
import { Link, Typography } from '@mui/material';
import { GridItemHome } from '../Home';

interface IRelatorios {
  privilegio: string;
  icon: string;
  nome: string;
  link: string;
}
export const tiposRelatorios: IRelatorios[] = [
  {
    privilegio: 'relatorios',
    icon: 'contact_emergency_rounded',
    nome: 'Time Sheet',
    link: '/relatorios/timesheet',
  },
  {
    privilegio: 'relatorios',
    icon: 'rule_folder_rounded',
    nome: 'Exportação Geral',
    link: '/relatorios/exportacao-geral',
  },
];

const Relatorios: React.FC = () => {
  return (
    <>
      <HeaderPages actionBack={true} arrowBackClick="/" icon="query_stats" title="Relatórios" />

      {tiposRelatorios.map((menuItem, index) => (
        <GridItem key={index} item xs={12} sm={6} md={3} lg={3} style={{ paddingBottom: '50px' }}>
          <Link onClick={() => history.push(menuItem.link as string)}>
            <GridItemHome>
              <Typography color={'primary.main'} variant="body2">
                <IconStato style={{}}>{menuItem.icon}</IconStato>
              </Typography>
              <Typography
                style={{ textAlign: 'center', minWidth: '50px' }}
                color={'primary.main'}
                variant="body2"
              >
                {menuItem.nome}
              </Typography>
            </GridItemHome>
          </Link>
        </GridItem>
      ))}
    </>
  );
};

export default Relatorios;
