import { GridColDef } from '@mui/x-data-grid';
import type { IExportacaoGeralResponse } from '../../../commons/genericTypes/relatorios';

const formatarData = (value: Date | null | undefined): string => {
  if (!value) return '';
  const date = new Date(value);
  return date.toLocaleDateString('pt-BR');
};

const formatarMoeda = (value: number | null | undefined): string => {
  if (!value) return '';
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(value);
};

export const createColumns = (): GridColDef<IExportacaoGeralResponse>[] => {
  return [
    {
      field: 'id',
      headerName: 'ID',
      width: 90,
    },
    {
      field: 'idDaynamics',
      headerName: 'ID Dynamics',
      width: 150,
    },
    {
      field: 'executivo',
      headerName: 'Executivo',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'dataNascimento',
      headerName: 'Data de Nascimento',
      width: 150,
      valueFormatter: (value: any) => formatarData(value),
    },
    {
      field: 'pretensaoSalarial',
      headerName: 'Pretensão Salarial',
      width: 150,
      valueFormatter: (value: any) => formatarMoeda(value),
    },
    {
      field: 'faixaRemuneracaoConsiderada',
      headerName: 'Faixa Remuneração',
      width: 180,
    },
    {
      field: 'metodoRecolocacao',
      headerName: 'Método Recolocação',
      width: 180,
    },
    {
      field: 'uf',
      headerName: 'UF',
      width: 80,
    },
    {
      field: 'telefonePrincipal',
      headerName: 'Telefone',
      width: 150,
    },
    {
      field: 'empresaCliente',
      headerName: 'Empresa Cliente',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'segmento',
      headerName: 'Segmento',
      width: 150,
    },
    {
      field: 'cargoAnterior',
      headerName: 'Cargo Anterior',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'programa',
      headerName: 'Programa',
      width: 150,
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      width: 120,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
    },
    {
      field: 'dataInicioPrograma',
      headerName: 'Início do Programa',
      width: 150,
      valueFormatter: (value: any, obj: any) => formatarData(value),
    },
    {
      field: 'dataTerminoPrograma',
      headerName: 'Término do Programa',
      width: 150,
      valueFormatter: (value: any, obj: any) => formatarData(value),
    },
    {
      field: 'dataExtensaoPrograma',
      headerName: 'Extensão do Programa',
      width: 150,
      valueFormatter: (value: any, obj: any) => formatarData(value),
    },
    {
      field: 'dataRecolocacao',
      headerName: 'Data Recolocação',
      width: 150,
      valueFormatter: (value: any, obj: any) => formatarData(value),
    },
    {
      field: 'empresaAtual',
      headerName: 'Empresa Atual',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'segmentoAtual',
      headerName: 'Segmento Atual',
      width: 150,
    },
    {
      field: 'cargoAtual',
      headerName: 'Cargo Atual',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'consultoresResponsaveis',
      headerName: 'Consultores Responsáveis',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'agentesMercado',
      headerName: 'Agentes de Mercado',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'qtdeProcessosSeletivos',
      headerName: 'Qtde. Processos Seletivos',
      width: 200,
      type: 'number',
    },
    {
      field: 'reclamante',
      headerName: 'Reclamante',
      width: 100,
      type: 'boolean',
    },
    {
      field: 'ra',
      headerName: 'RA',
      width: 80,
      type: 'boolean',
    },
    {
      field: 'sac',
      headerName: 'SAC',
      width: 80,
      type: 'boolean',
    },
  ];
};
