import { styled } from 'styled-components';
import Menu from '../../../components/FerramentasAdministrativas/Menu';
import { ButtonStato, GridContainer, SelectStato, TextFieldStato } from '../../../commons/styleds';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Icon,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridEventListener, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authAtom } from '../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { history } from '../../../helpers/history';
import HeaderPages from '../../../components/Generics/HeaderPages';
import { ISecao, ITbArea } from '../../../commons/genericTypes';
import { useSecaoActions } from '../../../states/conteudos/secoes/secao.actions';
import EditorHtml from '../../../components/EditorHtml/EditorHtml';
import { useTbAreaActions } from '../../../states/conteudos/tbAreas/tbArea.actions';
import { ptBR } from '@mui/material/locale';

const empty: ISecao = {
  id: 0,
  nome: '',
  descricao: '',
  visivel: true,
  ativo: true,
};

const Secoes: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const secaoActions = useSecaoActions();

  const tbAreaActions = useTbAreaActions();
  const [areas, setAreas] = useState<ITbArea[]>([]);
  const [areaTemp, setAreaTemp] = useState<ITbArea>();

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    params.row.descricao = params.row.descricao ?? '';
    atualizarFormulario(params.row);
  };

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [secoes, setSecoes] = useState<ISecao[]>([]);
  const [secaoTemp, setSecaoTemp] = useState<ISecao>();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 90,
    },
    {
      field: 'idArea',
      headerName: 'Menu',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ISecao;
        return areas?.find((f) => f.id == obj.idArea)?.nome;
      },
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 150,
    },
    {
      field: 'ordem',
      headerName: 'Posição',
      width: 100,
    },
    {
      field: 'visivel',
      headerName: 'Visivel Portal Assessorado',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ISecao;
        return <Checkbox checked={obj.visivel} inputProps={{ 'aria-label': 'controlled' }} />;
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ISecao;
        return (
          <Icon onClick={() => handleClickOpen(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
            delete
          </Icon>
        );
      },
    },
  ];

  const deleteValue = () => {
    secaoActions
      .delete(secaoTemp!.id!)
      .then((resp: ISecao[]) => {
        const sub = secoes.filter((f) => f.id !== secaoTemp!.id);
        setSecoes(sub);
        atualizarFormulario(empty);

        methods.reset(secaoTemp, { keepDefaultValues: true });
        setIdAtualizar(secaoTemp!.id);
        setLoadingForm(true);
        setOpen(false);
        setTimeout(() => {
          setLoadingForm(false);
        }, 1500);
      })
      .catch((err: any) => toast.warn(err));
  };

  const AtualizarUsuario = (data: any) => {
    secaoActions
      .update(data.id, data)
      .then((result: ISecao) => {
        const sub = secoes.filter((f) => f.id !== result.id);
        setSecoes(sub.concat(result));
        atualizarFormulario(empty);
        toast.success('Atualizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
      });
  };

  const onSubmit = async (data: any) => {
    setLoadingSave(true);

    if (data.id > 0) AtualizarUsuario(data);
    else {
      secaoActions
        .create(data)
        .then((result: ISecao) => {
          setSecoes(secoes.concat(result));
          atualizarFormulario(empty);
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });
    }

    setLoadingSave(false);
  };

  useEffect(() => {
    tbAreaActions
      .list()
      .then((resp: ITbArea[]) => setAreas(resp))
      .catch((err: any) => toast.warn(err));

    secaoActions
      .list()
      .then((resp: ISecao[]) => setSecoes(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setSecaoTemp(obj);
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
  };

  return (
    <BasePage>
      <HeaderPages
        actionBack={false}
        arrowBackClick="/crm/contatos"
        icon="auto_awesome_mosaic_rounded"
        title="Submenus"
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato id="formSecoe" onSubmit={methods.handleSubmit(onSubmit)}>
                <h3>{idAtualizar ? `Atualizar - ID: ${idAtualizar}` : 'Novo Cadastro'}</h3>

                <Controller
                  control={methods.control}
                  name="idArea"
                  render={({ field }) => (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Menus</InputLabel>
                      <SelectStato {...field} required label="Menus">
                        {areas.map((statu: ITbArea, index: number) => (
                          <MenuItem value={statu.id} key={index}>
                            {statu.nome}
                          </MenuItem>
                        ))}
                      </SelectStato>
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name="nome"
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant="outlined">
                      <FormInput {...field} inputRef={ref} value={field.value} label="Nome" />
                    </FormControl>
                  )}
                />
                <Controller
                  control={methods.control}
                  name="ordem"
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant="outlined">
                      <FormInput
                        {...field}
                        type="number"
                        inputRef={ref}
                        value={field.value}
                        label="Posição"
                      />
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name="descricao"
                  render={({ field: { ref, ...field } }) => (
                    <div style={{ paddingTop: '20px' }}>
                      <EditorHtml
                        setAsHtml={field.onChange}
                        initialHtml={field.value}
                        label={'Descrição'}
                      />
                    </div>
                  )}
                />

                <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                  <Grid item xs={12} md={4} sm={4}>
                    <Controller
                      control={methods.control}
                      name="ativo"
                      render={({ field }) => (
                        <FormControlLabelStato
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Ativo"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sm={8}>
                    <Controller
                      control={methods.control}
                      name="visivel"
                      render={({ field }) => (
                        <FormControlLabelStato
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          }
                          label="Visivel Portal Assessorado"
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <ButtonStato type="submit" variant="contained">
                    Salvar
                  </ButtonStato>
                  {idAtualizar ? (
                    <ButtonStato
                      color="error"
                      style={{ marginLeft: '25px' }}
                      onClick={() => atualizarFormulario(empty)}
                      variant="contained"
                    >
                      Cancelar
                    </ButtonStato>
                  ) : (
                    <></>
                  )}
                </div>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={secoes}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 10px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default Secoes;
