import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';

function useExportacaoGeralActions() {
  const recurso = 'relatorios/exportacao-geral';
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/${recurso}`;
  const baseActions = useBaseActions(recurso);
  const axiosWrapper = useAxiosWrapper();

  return {
    list: list,
    create: baseActions.create,
  };

  async function list(body: any) {
    const recursos = await axiosWrapper.post(`${baseUrl}/filtrar`, body);
    return recursos;
  }
}

export { useExportacaoGeralActions };
