import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  IconButton, 
  Tooltip 
} from '@mui/material';
import { 
  DataGrid, 
  GridColDef, 
  GridRowParams, 
  GridToolbar 
} from '@mui/x-data-grid';
import { 
  Delete as DeleteIcon, 
  CheckCircle as CheckCircleIcon, 
  Cancel as CancelIcon 
} from '@mui/icons-material';

import { IEventoParticipante } from '../../../../commons/genericTypes';
import { useParams } from 'react-router-dom';
import { useEventoParticipanteActions } from '../../../../states/conteudos/eventos/participantes/eventoParticipante.actions';

const Inscricoes: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const actions = useEventoParticipanteActions();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [participanteToDelete, setParticipanteToDelete] = useState<IEventoParticipante | null>(null);
  const [participantes, setParticipantes] = useState<IEventoParticipante[]>([]);

  useEffect(() => {
    actions.list(false, `?idEvento=${id}`).then((resp: IEventoParticipante[]) => {
      setParticipantes(resp);
    });
  }, [id])

  const handleDeleteConfirmOpen = (participante: IEventoParticipante) => {
    setParticipanteToDelete(participante);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteConfirmClose = () => {
    setOpenDeleteConfirm(false);
    setParticipanteToDelete(null);
  };

  const handleDelete = () => {
    if (participanteToDelete) {
      setParticipantes(prev => 
        prev.filter(p => p.id !== participanteToDelete.id)
      );

      actions.delete(participanteToDelete.id!);

      handleDeleteConfirmClose();
    }
  };

  const handleStatusUpdate = (
    id: number, 
    field: 'confirmado' | 'compareceu', 
    value: boolean
  ) => {
    const novoParticipante: IEventoParticipante = {
      ...participantes.find(p => p.id === id)!,
      [field]: value
    };
    actions.update(id, novoParticipante).then(() => {
      setParticipantes(prev => 
        prev.map(p => 
          p.id === id ? { ...p, [field]: value } : p
        )
      );
    });
  };

  const columns: GridColDef[] = [
    { 
      field: 'nome', 
      headerName: 'Nome', 
      flex: 1,
      minWidth: 180 
    },
    { 
      field: 'email', 
      headerName: 'Email', 
      flex: 1,
      minWidth: 200 
    },
    { 
      field: 'telefone', 
      headerName: 'Telefone', 
      flex: 0.7,
      minWidth: 150 
    },
    { 
      field: 'dtCadastro', 
      headerName: 'Data Inscrição', 
      type: 'dateTime',
      flex: 0.7,
      minWidth: 180,
      valueFormatter: (params: any) => {
        return params ? new Date(params)?.toLocaleString() : '';
      }
    },
    {
      field: 'confirmado',
      headerName: 'Confirmado',
      type: 'boolean',
      flex: 0.5,
      editable: true,
      renderCell: (params: any) => (
        <IconButton 
          onClick={() => handleStatusUpdate(params.row.id, 'confirmado', !params.value)}
        >
          {params.value ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
        </IconButton>
      )
    },
    {
      field: 'compareceu',
      headerName: 'Compareceu',
      type: 'boolean',
      flex: 0.5,
      editable: true,
      renderCell: (params: any) => (
        <IconButton 
          onClick={() => handleStatusUpdate(params.row.id, 'compareceu', !params.value)}
        >
          {params.value ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />}
        </IconButton>
      )
    },
    {
      field: 'actions',
      headerName: 'Ações',
      type: 'actions',
      flex: 0.5,
      getActions: (params: GridRowParams) => [
        <Tooltip title="Excluir">
          <IconButton 
            color="error" 
            onClick={() => handleDeleteConfirmOpen(params.row as IEventoParticipante)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ]
    }
  ];

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={participantes}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
      />

      <Dialog
        open={openDeleteConfirm}
        onClose={handleDeleteConfirmClose}
      >
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja excluir o participante {participanteToDelete?.nome}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteConfirmClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Inscricoes;