import { styled } from 'styled-components';
import { ButtonStato, GridContainer, SelectStato, TextFieldStato } from '../../../commons/styleds';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Icon,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridEventListener, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ptBR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authAtom } from '../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { history } from '../../../helpers/history';
import HeaderPages from '../../../components/Generics/HeaderPages';
import { IEvento, ITipoEvento, ModoEventoEnum } from '../../../commons/genericTypes';
import { useEventoActions } from '../../../states/conteudos/eventos/evento.actions';
import EditorHtml from '../../../components/EditorHtml/EditorHtml';
import { useTipoEventoActions } from '../../../states/conteudos/tipoEventos/tipoEvento.actions';
import { DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(utc);
dayjs.extend(timezone);

const Eventos: React.FC = () => {
  const eventoActions = useEventoActions();
  const tipoEventoActions = useTipoEventoActions();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [eventos, setEventos] = useState<IEvento[]>([]);
  const [tipoEventos, setTipoEventos] = useState<ITipoEvento[]>([]);
  const [eventoTemp, setEventoTemp] = useState<IEvento>();

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    history.push('/conteudo/eventos/' + params.id);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'idTipoEvento',
      headerName: 'Tipo de Evento',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return tipoEventos?.find((f) => f.id === obj.idTipoEvento)?.nome;
      },
    },
    {
      field: 'palestrante',
      headerName: 'Palestrante',
      width: 150,
    },
    {
      field: 'titulo',
      headerName: 'Titulo',
      width: 150,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 150,
      renderCell: (params) => (
        <div
          dangerouslySetInnerHTML={{ __html: params.value }}
          style={{ display: 'flex', alignItems: 'center', height: '100%' }}
        />
      ),
    },
    {
      field: 'ordem',
      headerName: 'Posição',
      width: 100,
    },
    {
      field: 'dtInicio',
      headerName: 'Data de Início',
      width: 200,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return new Date(obj.dtInicio!).toLocaleDateString();
      },
    },
    {
      field: 'dtFim',
      headerName: 'Data de Termino',
      width: 200,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return new Date(obj.dtFim!).toLocaleDateString();
      },
    },
    {
      field: 'link',
      headerName: 'Link do Evento',
      width: 150,
    },
    {
      field: 'bu',
      headerName: 'BU',
      width: 100,
    },
    {
      field: 'visivel',
      headerName: 'Visivel Portal Assessorado',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return <Checkbox checked={obj.visivel} inputProps={{ 'aria-label': 'controlled' }} />;
      },
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEvento;
        return (
          <Icon onClick={() => handleClickOpen(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
            delete
          </Icon>
        );
      },
    },
  ];

  const deleteValue = () => {
    eventoActions
      .delete(eventoTemp!.id!)
      .then((resp: IEvento[]) => {
        const sub = eventos.filter((f) => f.id !== eventoTemp!.id);
        setEventos(sub);
        toast.success('Apagado com sucesso !');
        setOpen(false);
      })
      .catch((err: any) => toast.warn(err));
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await eventoActions.list();
        setEventos(resp);

        const respTipoEventos = await tipoEventoActions.list();
        setTipoEventos(respTipoEventos);
      } catch (error) {
        toast.error('Erro ao Buscar Eventos');
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setEventoTemp(obj);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BasePage>
      <Grid container sx={{ alignItems: 'center' }}>
        <Grid item xs={6}>
          <HeaderPages actionBack={false} arrowBackClick="" icon="ballot_icon" title="Eventos" />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
            height: '40px',
          }}
        >
          <ButtonStato
            onClick={() => history.push('/conteudo/eventos/novo')}
            type="submit"
            variant="contained"
          >
            <Icon>add</Icon>
            Adicionar Evento
          </ButtonStato>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <DataGrid
              getRowId={(row) => row?.id}
              onRowClick={handleRowClick}
              rows={eventos}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                borderRadius: '20px',
              }}
            />
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

export const BasePage = styled.div`
  padding: 15px;
`;

export const Container = styled(GridContainer)`
  padding-top: 10px;
  padding-left: 20px;
`;

export default Eventos;
