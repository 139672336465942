import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Drawer,
  Modal,
  Typography,
  Icon,
  LinearProgress,
  InputLabel,
  MenuItem,
  Container,
  AppBar,
  Toolbar,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ptBR } from '@mui/material/locale';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import {
  ButtonStato,
  IconDrawer,
  SelectStato,
  TextFieldStato,
} from '../../../../../commons/styleds';
import { authAtom } from '../../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../../helpers/history';
import { useHistoricoAcoesActions } from '../../../../../states/crm/contatoAuxiliares/historicos/historicoAcoes.actions';
import { IAcoes, IHistoricoAcoes, ITipoDeAcao } from '../../../../../commons/genericTypes';
import { GridItemCadastro } from '../../../Comanda';
import { styled } from 'styled-components';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTipoDeAcaoActions } from '../../../../../states/config/ConfiguracaoSistema/tiposDeAcoes/tipoDeAcao.actions';
import dayjs from 'dayjs';
import InputArquivo from '../../../../../components/InputArquivo/InputArquivo';

const empty: IHistoricoAcoes = {
  id: 0,
  idTipo: 0,
  idComanda: 0,
  dtPrevista: undefined,
  observacoes: '',
  anexo: undefined,
  realizada: false,
  habilitarEmpresas: false,
  habilitarAssessorado: false,
  notificarMesa: false,
  notificarPool: false,
  notificarCex: false,
  ativo: false,
};

interface IParams {
  contatoId?: string;
  comandaId?: number;
}

enum ActionType {
  Create,
  Edit,
}

const HistoricoComponent: React.FC<IParams> = (props) => {
  const { contatoId, comandaId } = props;
  const auth = useRecoilValue(authAtom);
  const historicoAcoesActions = useHistoricoAcoesActions();
  const tipoDeAcaoActions = useTipoDeAcaoActions();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [historicos, setHistoricos] = useState<IHistoricoAcoes[]>([]);
  const [historico, setHistorico] = useState<IHistoricoAcoes>(empty);
  const [tiposDeAcoes, setTiposDeAcoes] = useState<ITipoDeAcao[]>([]);
  const [editAcao, setEditAcao] = useState<boolean>(false);
  const [acaoEmEdicao, setAcaoEmEdicao] = useState<IHistoricoAcoes>(empty);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [acaoDeleteId, setAcaoDeleteId] = useState<number>(0);
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [arquivoUrl, setArquivoUrl] = useState('');

  useEffect(() => {
    tipoDeAcaoActions
      .list()
      .then((resp: ITipoDeAcao[]) => {
        resp = resp.sort((a, b) => a.nome!.localeCompare(b.nome!));
        setTiposDeAcoes(resp.filter((f) => f.ativo))
      })
      .catch((err) => toast.warn(err));

    historicoAcoesActions
      .list(false, `?comandaId=${comandaId}`)
      .then((data: IHistoricoAcoes[]) => setHistoricos(data.filter((f) => f.ativo)));

    setLoading(false);
  }, []);

  const openDrawerHistorico = (result: any) => {
    setHistorico(empty);
    setOpen(result);

    if (!result) {
      setEditAcao(false);
      setNomeArquivo('');
      setArquivoUrl('');
    }
  };

  const HandleEditar = (acaoEmEdicao: any) => {
    acaoEmEdicao!.dtPrevista = dayjs(new Date(acaoEmEdicao.dtPrevista));
    setEditAcao(true);
    setAcaoEmEdicao(acaoEmEdicao);

    if (acaoEmEdicao.anexoURL) {
      setNomeArquivo(acaoEmEdicao.anexo);
      setArquivoUrl(acaoEmEdicao.anexoURL);
    }

    openDrawerHistorico(true);
  };

  const submitAcao = (actionType: ActionType) => {
    setLoadingSave(true);

    if (actionType === ActionType.Create) {
      const data: any = {
        idTipo: historico.idTipo,
        idComanda: comandaId,
        dtPrevista: historico.dtPrevista,
        observacoes: historico.observacoes,
        anexoFile: historico.anexo,
        realizada: historico.realizada,
        habilitarEmpresas: historico.habilitarEmpresas,
        habilitarAssessorado: historico.habilitarAssessorado,
        notificarMesa: historico.notificarMesa,
        notificarPool: historico.notificarPool,
        notificarCex: historico.notificarCex,
        ativo: true,
      };

      historicoAcoesActions
        .createFormData(data)
        .then((result: IHistoricoAcoes) => {
          setHistoricos(historicos.concat(result as IHistoricoAcoes));
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });
    }

    if (actionType === ActionType.Edit) {
      const data: any = {
        idTipo: acaoEmEdicao.idTipo,
        idComanda: acaoEmEdicao.idComanda,
        dtPrevista: acaoEmEdicao.dtPrevista,
        observacoes: acaoEmEdicao.observacoes,
        anexoFile: acaoEmEdicao.anexo,
        realizada: acaoEmEdicao.realizada,
        habilitarEmpresas: acaoEmEdicao.habilitarEmpresas,
        habilitarAssessorado: acaoEmEdicao.habilitarAssessorado,
        notificarMesa: acaoEmEdicao.notificarMesa,
        notificarPool: acaoEmEdicao.notificarPool,
        notificarCex: acaoEmEdicao.notificarCex,
        ativo: acaoEmEdicao.ativo,
      };

      historicoAcoesActions
        .updateFormData(acaoEmEdicao.id!, data)
        .then((result: IHistoricoAcoes) => {
          const acao = historicos.filter((f) => f.id !== result.id);
          setHistoricos(acao.concat(result).sort((a, b) => a.id! - b.id!));
          toast.success('Atualizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Atualizar!');
        });
    }

    openDrawerHistorico(false);
    setLoadingSave(false);
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <Container maxWidth="xl" sx={{ mt: 2 }}>
            <AppBar
              position="sticky"
              color="default"
              elevation={1}
              sx={{ top: 65, zIndex: (theme) => theme.zIndex.appBar }}
            >
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" component="div">
                  Histórico de Ações
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => openDrawerHistorico(true)}
                >
                  Adicionar Novo
                </Button>
              </Toolbar>
            </AppBar>
            <List sx={{ mt: 0 }}>
              {historicos?.map((item, index) => (
                <div key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          color="primary"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => HandleEditar(item)}
                        >
                          {tiposDeAcoes.find((t) => t.id === item.idTipo)?.nome}
                        </Typography>
                      }
                      secondary={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>{item.observacoes}</div>
                          <div>{new Date(item.dtCadastro as any).toLocaleDateString()}</div>
                        </div>
                      }
                    />
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
          </Container>

          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerHistorico(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerHistorico(false)}>close</IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant="h5" sx={{ paddingBottom: '20px' }}>
                    {editAcao ? 'Editar Ação' : 'Adicionar Ação'}
                  </Typography>
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Tipos de Ações</InputLabel>
                <SelectStato
                  required
                  value={editAcao ? acaoEmEdicao.idTipo : undefined}
                  label="Tipos de Ações"
                  onChange={(event: any) => {
                    editAcao
                      ? setAcaoEmEdicao({ ...acaoEmEdicao, idTipo: event.target.value })
                      : setHistorico({ ...historico, idTipo: event.target.value });
                  }}
                >
                  {tiposDeAcoes?.map((acao: IAcoes, index: number) => {
                    return (
                      <MenuItem value={acao.id} key={index}>
                        {acao.nome}
                      </MenuItem>
                    );
                  })}
                </SelectStato>
              </FormControl>

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Realizada"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.realizada : historico.realizada}
                    onClick={() => {
                      editAcao
                        ? setAcaoEmEdicao({ ...acaoEmEdicao, realizada: !acaoEmEdicao.realizada })
                        : setHistorico({ ...historico, realizada: !historico.realizada });
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Notificar Mesa"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.notificarMesa : historico.notificarMesa}
                    onClick={() => {
                      editAcao
                        ? setAcaoEmEdicao({
                            ...acaoEmEdicao,
                            notificarMesa: !acaoEmEdicao.notificarMesa,
                          })
                        : setHistorico({ ...historico, notificarMesa: !historico.notificarMesa });
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Notificar Pool"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.notificarPool : historico.notificarPool}
                    onClick={() => {
                      editAcao
                        ? setAcaoEmEdicao({
                            ...acaoEmEdicao,
                            notificarPool: !acaoEmEdicao.notificarPool,
                          })
                        : setHistorico({ ...historico, notificarPool: !historico.notificarPool });
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Habilitar Empresa"
                control={
                  <Checkbox
                    checked={
                      editAcao ? acaoEmEdicao.habilitarEmpresas : historico.habilitarEmpresas
                    }
                    onClick={() => {
                      editAcao
                        ? setAcaoEmEdicao({
                            ...acaoEmEdicao,
                            habilitarEmpresas: !acaoEmEdicao.habilitarEmpresas,
                          })
                        : setHistorico({
                            ...historico,
                            habilitarEmpresas: !historico.habilitarEmpresas,
                          });
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Habilitar Assessorado"
                control={
                  <Checkbox
                    checked={
                      editAcao ? acaoEmEdicao.habilitarAssessorado : historico.habilitarAssessorado
                    }
                    onClick={() => {
                      editAcao
                        ? setAcaoEmEdicao({
                            ...acaoEmEdicao,
                            habilitarAssessorado: !acaoEmEdicao.habilitarAssessorado,
                          })
                        : setHistorico({
                            ...historico,
                            habilitarAssessorado: !historico.habilitarAssessorado,
                          });
                    }}
                  />
                }
              />

              <FormControlLabel
                required
                sx={{ marginTop: '20px' }}
                label="Notificar Cex"
                control={
                  <Checkbox
                    checked={editAcao ? acaoEmEdicao.notificarCex : historico.notificarCex}
                    onClick={() => {
                      editAcao
                        ? setAcaoEmEdicao({
                            ...acaoEmEdicao,
                            notificarCex: !acaoEmEdicao.notificarCex,
                          })
                        : setHistorico({ ...historico, notificarCex: !historico.notificarCex });
                    }}
                  />
                }
              />

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'obsInput'}
                  type="text"
                  label="Observação"
                  multiline
                  maxRows={4}
                  value={editAcao ? acaoEmEdicao.observacoes : historico?.observacoes}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    editAcao
                      ? setAcaoEmEdicao({ ...acaoEmEdicao, observacoes: event.target.value })
                      : setHistorico({ ...historico, observacoes: event.target.value });
                  }}
                />
              </FormControl>

              <InputArquivo
                isEdit={editAcao}
                objOnEdit={acaoEmEdicao}
                setObjOnEdit={setAcaoEmEdicao}
                newObj={historico}
                setNewObj={setHistorico}
                nameFile={nomeArquivo}
                setNameFile={setNomeArquivo}
                urlFile={arquivoUrl}
                setUrlFile={setArquivoUrl}
              />
            
              {loadingSave && <LinearProgress />}

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato
                  variant="contained"
                  onClick={() => submitAcao(editAcao ? ActionType.Edit : ActionType.Create)}
                  disabled={editAcao ? acaoEmEdicao.idTipo! <= 0 : historico.idTipo! <= 0}
                >
                  {editAcao ? 'Editar' : 'Adicionar'}
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
    </>
  );
};

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  position: fixed;
  bottom: 5%;
  right: 4%;
  z-index: 50;
`;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
};

export default HistoricoComponent;
