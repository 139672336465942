import { IConteudo } from '../../../commons/genericTypes';
import { useAxiosWrapper } from '../../../services/axiosWrapper';
import { useBaseActions } from '../../base.actions';
import { conteudoAtom } from './Conteudo';


function useConteudoActions() {
  const baseActions = useBaseActions('Conteudo', conteudoAtom);
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/Conteudo`;
  const axiosWrapper = useAxiosWrapper();

  return {
    list: baseActions.list,
    get: baseActions.get,
    update: baseActions.update,
    create: baseActions.create,
    delete: baseActions.delete,
    createFormData: baseActions.createFormData,
    uploadAnexo: uploadAnexoFormData
  };

  async function uploadAnexoFormData(obj: object | any) {
    const payload: IConteudo = {
      anexoFile: obj,
      areas: [],
      idAreas: [],
      idSecaos: [],
      secaos: [],
      visivel: true
    }

    const recurso = await axiosWrapper.postFormData(`${baseUrl}/upload-anexo`, payload);
    return recurso;
  }
}

export { useConteudoActions };
