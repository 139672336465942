/* eslint-disable max-len */
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IEmpresa } from '../../../../../commons/genericTypes';
import { ButtonStato, GridContainer } from '../../../../../commons/styleds';
import { IParams } from '../../../MiniBio/Endereco';
import { FormInput } from '../../NovaEmpresa';
import { useEmpresasActions } from '../../../../../states/empresas/empresa.actions';
import styled from 'styled-components';
import { history } from '../../../../../helpers/history';

const empty: IEmpresa = {
  id: 0,
  cnpj: '',
  razaosocial: '',
  nomefantasia: '',
  matriz: false,
  ativo: true
};

interface IDadosEmpresa {
  empresa: IEmpresa | undefined;
  setEmpresa: Function;
}

const DadosEmpresa: React.FC<IDadosEmpresa> = ({ empresa, setEmpresa }) => {
  const methods = useForm<IEmpresa>({ defaultValues: empty });
  const { id } = useParams<IParams>();
  const empresaActions = useEmpresasActions();
  const [idDeletar, setIdDeletar] = useState<number>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (empresa) {
      methods.reset(empresa);
      return;
    }

    empresaActions.get(Number(id))
      .then((empresaResp) => {
        setTimeout(() => {
          setEmpresa(empresaResp);
          methods.reset(empresaResp);
        }, 1000
        );
      })
      .catch(() => {
        toast.error('Falha ao buscar empresa');
        history.push('/crm/empresa')
      });
  }, [])

  const onSubmit = async (data: IEmpresa) => {
    setLoading(true);
    const request: any = { ...data };

    await empresaActions.update(request.id, request)
    toast.success('Empresa atualizada com sucesso');
    setLoading(false);
  };

  const handleClickOpen = (obj: any) => {
    setIdDeletar(obj.id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteValue = () => {
    empresaActions
      .delete(idDeletar!)
      .then(() => {
        setOpen(false);
        history.push('/crm/empresa')
      })
      .catch((err: any) => toast.warn(err));
  };

  return (
    <>
      <GridContainer container columnSpacing={2}>
        <FormProvider {...methods}>
          <form
            id="formEmpresa"
            style={{ margin: '-10px -20px !important' }}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {!loading ? (
              <GridItemCadastro container rowGap={1.5}>
                <GridItemCadastro item xs={12} md={6}>
                  <Controller
                    control={methods.control}
                    name='nomefantasia'
                    render={({ field: { ref, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        <FormInput
                          {...field}
                          inputRef={ref}
                          label='Nome Fantasia'
                        />
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={6}>
                  <Controller
                    control={methods.control}
                    name='razaosocial'
                    render={({ field: { ref, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        <FormInput
                          {...field}
                          inputRef={ref}
                          label='Razão social'
                        />
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={6}>
                  <Controller
                    control={methods.control}
                    name='cnpj'
                    render={({ field: { ref, ...field } }) => (
                      <FormControl required fullWidth variant='outlined'>
                        <FormInput
                          {...field}
                          inputRef={ref}
                          label='CNPJ'
                        />
                      </FormControl>
                    )}
                  />
                </GridItemCadastro>

                <GridItemCadastroBtnSalvar item xs={11} md={11}>
                  <ButtonStato type="submit" variant="contained">
                    Salvar
                  </ButtonStato>

                  <ButtonStato onClick={() => handleClickOpen(empresa)} color="error" variant="contained">
                    Excluir empresa
                  </ButtonStato>
                </GridItemCadastroBtnSalvar>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Tem certeza que dejesa excluir esse item ?'}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={deleteValue}>Sim</Button>
                    <Button variant="contained" color="error" onClick={handleClose} autoFocus>
                      Cancelar
                    </Button>
                  </DialogActions>
                </Dialog>

              </GridItemCadastro>
            ) : (

              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            )}
          </form>
        </FormProvider>
      </GridContainer>
    </>
  )
}

const GridItemCadastro = styled(Grid)`
  padding: 5px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default DadosEmpresa;

