/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { ContentState, EditorState, Modifier, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled from 'styled-components';
import { useConteudoActions } from '../../states/conteudos/conteudos/conteudo.actions';

interface IEditorHtmlProps {
  setAsHtml: (html: string) => void;
  initialHtml?: string;
  label?: string;
}

const EditorHtml: React.FC<IEditorHtmlProps> = (props) => {
  const conteudoActions = useConteudoActions();
  const { setAsHtml, initialHtml, label } = props;
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  useEffect(() => {
    if (initialHtml === undefined)
      return;

    const contentBlock = htmlToDraft(initialHtml);
    if (!contentBlock)
      return;

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
  }, [])

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    setAsHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const handleEmbeddedLink = (link: string): string => {
    const patterns = [
      {
        regex: /https:\/\/www\.youtube\.com\/watch\?v=([\w-]+)/,
        embedBaseUrl: 'https://www.youtube.com/embed',
      },
      {
        regex: /https:\/\/youtu\.be\/([\w-]+)/,
        embedBaseUrl: 'https://www.youtube.com/embed',
      },
      {
        regex: /https:\/\/vimeo\.com\/([\d]+)/,
        embedBaseUrl: 'https://player.vimeo.com/video',
      },
      {
        regex: /https:\/\/player\.vimeo\.com\/video\/([\d]+)/,
        embedBaseUrl: 'https://player.vimeo.com/video',
      },
    ];

    for (const { regex, embedBaseUrl } of patterns) {
      const match = link.match(regex);

      if (match && match[1]) {
        return `${embedBaseUrl}/${match[1]}`;
      }
    }

    return link;
  };

  const callback = async (file: File) => {
    return new Promise((resolve, reject) => {
      conteudoActions.uploadAnexo(file)
        .then((res) => {
          const fileName = file.name;
          const fileUrl = res;

          if (!fileUrl) {
            reject('Erro ao carregar o arquivo.');
            return;
          }

          if (file.type.startsWith('image/')) {
            return resolve({ data: { link: fileUrl } });
          } else {
            setEditorState((prevState) =>
              insertFileAsLink(prevState, fileUrl as string, fileName)
            );

            return resolve({ data: { link: '' } });
          }

        })
        .catch((error) => {
          reject(`Erro ao carregar o arquivo: ${error.message}`);
        });

    });
  };

  const insertFileAsLink = (editorState: EditorState, fileUrl: string, fileName: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: fileUrl, target: '_blank' }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newContentState = Modifier.insertText(
      contentState,
      editorState.getSelection(),
      fileName,
      undefined,
      entityKey
    );

    return EditorState.push(editorState, newContentState, 'insert-characters');
  };

  return (
    <DivEditorHtmlStato>
      <p style={{ color: '#666' }}>{label}</p>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            uploadCallback: callback,
            alignmentEnabled: true,
            inputAccept:
              [
                'application/pdf',
                'text/plain',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/msword',
                'application/vnd.ms-excel',
                'image/jpeg',
                'image/png',
                'image/webp'
              ]
          },
          embedded: {
            defaultSize: {
              height: 'auto',
              width: 'auto',
            },
            embedCallback: (link: string) => {
              const transformedLink = handleEmbeddedLink(link);
              return transformedLink;
            },
          },
        }}
      />
    </DivEditorHtmlStato>
  );
}

const DivEditorHtmlStato = styled.div`
  border: 1px solid #cbcbcb;
  border-radius: 20px !important;
  padding: 2px 15px 20px;
  &:hover {
    border: 1px solid #333333;
  }

  &:focus-within {
    border: 2px solid #118bd7;
  }

  >.wrapperClassName {
    >.toolbarClassName {
      border: 1px solid #cbcbcb;
      border-radius: 20px 20px 5px 5px !important;

      &:hover {
        border: 1px solid #333333;
      }
    }

    >.editorClassName {
      border: 1px solid #cbcbcb;
      border-radius: 5px 5px 20px 20px !important;
      min-height: 100px;
      padding: 2px 8px;
      content: 'asdfaef';

      &:hover {
        border: 1px solid #333333;
      }
    }
  }
`;

export default EditorHtml;
