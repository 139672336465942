import { Autocomplete, FormControl, Grid2, InputLabel, MenuItem, Radio, RadioGroup, FormControlLabel, FormLabel, CircularProgress } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IStatus, type IConsultor, type IMetodologia, type IPlanosTemplate } from '../../../commons/genericTypes';
import { IExportacaoGeralFilter, IExportacaoGeralResponse } from '../../../commons/genericTypes/relatorios';
import { ButtonStato, SelectStato, TextFieldStato } from '../../../commons/styleds';
import HeaderPages from '../../../components/Generics/HeaderPages';
import { useStatusActions } from '../../../states/config/Programas/status/status.actions';
import { DivConteudo, PaperRelatorio } from '../TimeSheet';
import { ModeloComercial, TipoConsultor } from '../../../commons/enums';
import { usePlanosTemplateActions } from '../../../states/config/Programas/planosTempletes/planosTemplate.actions';
import { useMetodologiaActions } from '../../../states/config/Programas/metodologias/metodologia.actions';
import { useConsultorActions } from '../../../states/config/CadastroUsuarios/consultores/consultor.actions';
import { createColumns } from './columns';
import { DataGrid } from '@mui/x-data-grid';
import { useExportacaoGeralActions } from '../../../states/relatorios/exportacaoGeral/exportacaoGeral.actions';

const empty: IExportacaoGeralFilter = {
  nome: '',
  nomeSemelhantes: false,
  email: '',
  emailSemelhantes: false,
  idDaynamics: '',
  modelosComerciais: [],
  bus: [],
  status: [],
  planos: [],
  metodologias: [],
  consultores: [],
  dataInicio: undefined,
  dataFim: undefined,
  dataExtensao: undefined,
  reclamante: false,
  ra: false,
  sac: false
};

const ExportacaoGeral: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const actions = useExportacaoGeralActions();
  const [loading, setLoading] = useState(false);
  const [loadingRelatorio, setLoadingRelatorio] = useState(false);

  const statusActions = useStatusActions();
  const [status, setStatus] = useState<IStatus[]>([]);

  const planosActions = usePlanosTemplateActions();
  const [planos, setPlanos] = useState<IPlanosTemplate[]>([]);

  const metodologiaActions = useMetodologiaActions();
  const [metodologias, setMetodologias] = useState<IMetodologia[]>([]);

  const consultoresActions = useConsultorActions();
  const [consultores, setConsultores] = useState<IConsultor[]>([]);

  const [resultado, setResultado] = useState<IExportacaoGeralResponse[]>([]);

  const getStatusDb = () => {
    statusActions
      .list()
      .then((resp) => {
        setStatus(resp);
      })
      .catch((err) => toast.warn(err));
  };

  const getPlanosDb = () => {
    planosActions
      .list()
      .then((resp) => {
        setPlanos(resp);
      })
      .catch((err) => toast.warn(err));
  };

  const getMetodologiasDb = () => {
    metodologiaActions
      .list()
      .then((resp) => {
        setMetodologias(resp);
      })
      .catch((err) => toast.warn(err));
  };

  const getConsultoresDb = () => {
    consultoresActions
      .list()
      .then((resp) => {
        setConsultores(resp.resultado ?? []);
      })
      .catch((err) => toast.warn(err));
  };

  useEffect(() => {
    getStatusDb();
    getPlanosDb();
    getMetodologiasDb();
    getConsultoresDb();
  }, []);

  const validaPodeEnviar = () => {
    return true;
  };

  const filtrarRelatorio = async (e: any) => {
    e.preventDefault();
    if (!validaPodeEnviar()) return;
    
    setResultado([]);
    setLoading(true);
    try {
      const resp = await actions.list(methods.getValues());
      setResultado(resp);
      if (resp.length === 0) {
        toast.warn('Nenhum resultado encontrado');
      }
    } catch (error: any) {
      toast.error(error?.message || 'Erro ao filtrar relatório');
    } finally {
      setLoading(false);
    }
  };

  const gerarRelatorio = async (e: any) => {
    e.preventDefault();
    if (!validaPodeEnviar()) return;
    
    setResultado([]);
    setLoadingRelatorio(true);
    try {
      const resp = await actions.create(methods.getValues());
      window.open(resp, '_blank', 'noopener,noreferrer');
      toast.success('Relatório gerado com sucesso');
    } catch (error: any) {
      toast.error(error?.message || 'Erro ao gerar relatório');
    } finally {
      setLoadingRelatorio(false);
    }
  };

  const limparFiltros = (e: any) => {
    e.preventDefault();
    methods.reset(empty);
  };

  return (
    <>
      <HeaderPages
        actionBack={true}
        arrowBackClick="/relatorios"
        icon="contact_emergency_rounded"
        title="Time Sheet Consultor"
      />

      <DivConteudo>
        <Grid2 size={{ xs: 12 }}>
          <h2>Preencha as informações para extrair o relatório:</h2>
        </Grid2>

        <form>
          <PaperRelatorio elevation={3} sx={{ marginBottom: "20px" }}>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="nome"
                  render={({ field }) => (
                    <TextFieldStato
                      {...field}
                      label="Nome"
                      fullWidth
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="nomeSemelhantes"
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth>
                      <FormLabel>Busca por Nome Semelhante</FormLabel>
                      <RadioGroup
                        row
                        value={value.toString()}
                        onChange={(e) => onChange(e.target.value === 'true')}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="email"
                  render={({ field }) => (
                    <TextFieldStato
                      {...field}
                      label="Email"
                      fullWidth
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="emailSemelhantes"
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth>
                      <FormLabel>Busca por Email Semelhante</FormLabel>
                      <RadioGroup
                        row
                        value={value.toString()}
                        onChange={(e) => onChange(e.target.value === 'true')}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="idDaynamics"
                  render={({ field }) => (
                    <TextFieldStato
                      {...field}
                      label="ID Dynamics"
                      fullWidth
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="modelosComerciais"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Autocomplete
                      multiple
                      options={Object.values(ModeloComercial)}
                      onChange={(_, newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextFieldStato
                          {...params}
                          label="Modelos Comerciais"
                          placeholder="Selecione Modelos Comerciais"
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="bus"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Autocomplete
                      multiple
                      options={Object.values(TipoConsultor)}
                      onChange={(_, newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextFieldStato
                          {...params}
                          label="BUs"
                          placeholder="Selecione BUs"
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="status"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Autocomplete
                      multiple
                      options={status.map(s => s.id)}
                      getOptionLabel={(option) =>
                        status.find(s => s.id === option)?.nome || ''
                      }
                      onChange={(_, newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextFieldStato
                          {...params}
                          label="Status"
                          placeholder="Selecione Status"
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="planos"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Autocomplete
                      multiple
                      options={planos?.map(p => p.nome!) || []}
                      value={value as any || []}
                      onChange={(_, newValue) => onChange(newValue)}
                      renderInput={(params) => (
                        <TextFieldStato
                          {...params}
                          label="Planos"
                          placeholder="Selecione Planos"
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="metodologias"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Autocomplete
                      multiple
                      options={metodologias?.map(m => m.id!) || []}
                      value={value as any || []}
                      onChange={(_, newValue) => onChange(newValue)}
                      getOptionLabel={(option) =>
                        metodologias.find(m => m.id === option)?.nome || ''
                      }
                      renderInput={(params) => (
                        <TextFieldStato
                          {...params}
                          label="Metodologia"
                          placeholder="Selecione Metodologia"
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="consultores"
                  render={({ field: { onChange, value, ...field } }) => (
                    <Autocomplete
                      multiple
                      options={consultores?.map(c => c.id!) ?? []}
                      value={value as any || []}
                      onChange={(_, newValue) => onChange(newValue)}
                      getOptionLabel={(option) =>
                        consultores?.find(c => c.id === option)?.nome || ''
                      }
                      renderInput={(params) => (
                        <TextFieldStato
                          {...params}
                          label="Consultores"
                          placeholder="Selecione Consultores"
                        />
                      )}
                      {...field}
                    />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="dataInicio"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : null}
                        label="Data Início"
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="dataFim"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : null}
                        label="Data Fim"
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Controller
                  control={methods.control}
                  name="dataExtensao"
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        {...field}
                        value={field.value ? dayjs(field.value) : null}
                        label="Data Extensão"
                        format="DD/MM/YYYY"
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            variant: 'outlined',
                          },
                        }}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <Controller
                  control={methods.control}
                  name="reclamante"
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth>
                      <FormLabel>Reclamante</FormLabel>
                      <RadioGroup
                        row
                        value={value.toString()}
                        onChange={(e) => onChange(e.target.value === 'true')}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <Controller
                  control={methods.control}
                  name="ra"
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth>
                      <FormLabel>RA</FormLabel>
                      <RadioGroup
                        row
                        value={value.toString()}
                        onChange={(e) => onChange(e.target.value === 'true')}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 4 }}>
                <Controller
                  control={methods.control}
                  name="sac"
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth>
                      <FormLabel>SAC</FormLabel>
                      <RadioGroup
                        row
                        value={value.toString()}
                        onChange={(e) => onChange(e.target.value === 'true')}
                      >
                        <FormControlLabel value="true" control={<Radio />} label="Sim" />
                        <FormControlLabel value="false" control={<Radio />} label="Não" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid2>

              <Grid2 sx={{ display: 'flex', width: '100%' }}>
                <Grid2 sx={{ marginRight: '15px' }}>
                  <ButtonStato 
                    type="submit" 
                    variant="outlined" 
                    onClick={filtrarRelatorio}
                    disabled={loading || loadingRelatorio}
                  >
                    {loading ? 'Filtrando...' : 'Filtrar'}
                  </ButtonStato>
                </Grid2>
                <Grid2>
                  <ButtonStato
                    type="submit"
                    variant="contained"
                    onClick={gerarRelatorio}
                    disabled={loading || loadingRelatorio}
                  >
                    {loadingRelatorio ? 'Gerando...' : 'Extrair Relatório'}
                  </ButtonStato>
                </Grid2>

                <Grid2 sx={{ marginLeft: 'auto' }}>
                  <ButtonStato 
                    type="submit" 
                    variant="outlined" 
                    onClick={limparFiltros} 
                    color="error"
                    disabled={loading || loadingRelatorio}
                  >
                    Limpar Filtros
                  </ButtonStato>
                </Grid2>
              </Grid2>
            </Grid2>
          </PaperRelatorio>
        </form>

        {loading && (
          <Grid2 container justifyContent="center" sx={{ marginTop: '20px' }}>
            <CircularProgress />
          </Grid2>
        )}

        {loadingRelatorio && (
          <Grid2 container justifyContent="center" sx={{ marginTop: '20px' }}>
            <CircularProgress />
          </Grid2>
        )}

        {resultado && resultado.length > 0 && (
          <Grid2 container>
            <Grid2 size={{ xs: 12 }}>
              <h2>Resultado:</h2>
            </Grid2>

            <Grid2 size={{ xs: 12 }} sx={{ marginBottom: '100px'}}>
              <DataGrid
                rows={resultado}
                columns={createColumns()}
                pagination
              />
            </Grid2>
          </Grid2>
        )}
      </DivConteudo>
    </>
  );
};

export default ExportacaoGeral;
