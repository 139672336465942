import { RecoilState, useSetRecoilState } from 'recoil';
import { useAxiosWrapper } from '../services/axiosWrapper';

function useBaseActions(recurso: string, state?: RecoilState<any>) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/v1/${recurso}`;
  const axiosWrapper = useAxiosWrapper();
  const setState = state ? useSetRecoilState(state) : undefined;

  return {
    list: list,
    get: get,
    update: update,
    patch: patch,
    create: create,
    delete: deleteObj,
    createFormData: createFormData,
    updateFormData: updateFormData
  };

  async function list(salvarNoState: boolean = false, query: string = '') {
    let url = baseUrl;
    if (query !== '') url += query;
    const recursos = await axiosWrapper.get(url);
    if (salvarNoState && setState !== undefined) setState(recursos);

    return recursos;
  }

  async function get(id: number) {
    const recurso = await axiosWrapper.get(`${baseUrl}/${id}`);
    return recurso;
  }

  async function update(id: number, obj: object | any) {
    const recurso = await axiosWrapper.put(`${baseUrl}/${id}`, obj);
    return recurso;
  }

  async function patch(id: number, obj: object | any) {
    const recurso = await axiosWrapper.patch(`${baseUrl}/${id}`, obj);
    return recurso;
  }

  async function create(obj: object | any) {
    const recurso = await axiosWrapper.post(`${baseUrl}`, obj);
    return recurso;
  }

  async function deleteObj(id: number) {
    const recurso = await axiosWrapper.delete(`${baseUrl}/${id}`);
    return recurso;
  }

  async function createFormData(obj: object | any) {
    const recurso = await axiosWrapper.postFormData(`${baseUrl}`, obj);
    return recurso;
  }

  async function updateFormData(id: number, obj: object | any) {
    const recurso = await axiosWrapper.putFormData(`${baseUrl}/${id}`, obj);
    return recurso;
  }
}

export { useBaseActions };
