/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-len */
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  ButtonStato,
  GridContainerCenter,
  SelectStato,
  TextFieldStato,
} from '../../../commons/styleds';
import MultiSelect from '../../../components/Generics/MultiSelect';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { styled } from 'styled-components';
import {
  IConfigArea,
  IConfigSegmento,
  IConfigSubArea,
  IFaixaSalarial,
  IIdioma,
  IMercadoAlvo,
  INivelDeCargo,
  ISetor,
  IUsuario,
  TipoRegiao,
  type IMercadoAlvoRegiao,
} from '../../../commons/genericTypes';
import { useConfigAreaActions } from '../../../states/config/ConfiguracaoSistema/areas/configArea.actions';
import { useConfigSubAreaActions } from '../../../states/config/ConfiguracaoSistema/subAreas/configSubArea.actions';
import { useConfigSegmentoActions } from '../../../states/config/ConfiguracaoSistema/segmentos/configSegmento.actions';
import { useSetorActions } from '../../../states/config/ConfiguracaoSistema/setores/setor.actions';
import { useNivelDeCargoActions } from '../../../states/config/ConfiguracaoSistema/niveisDeCargos/nivelDeCargo.actions';
import MultiSelectNome from '../../../components/Generics/MultiSelectNome';
import { useIdiomaConfigActions } from '../../../states/config/ConfiguracaoSistema/idiomas/idioma.actions';
import { useMercadoAlvoActions } from '../../../states/crm/mercadoAlvo/mercadoAlvo.actions';
import { useUsuarioActions } from '../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { useFaixaSalarialActions } from '../../../states/config/ConfiguracaoSistema/faixasSalariais/faixaSalarial.actions';
import { useGrupoActions } from '../../../states/grupos/grupo.actions';
import TableRegiao from './components/tableRegiao';

interface IParams {
  id?: string;
}

const empty: IMercadoAlvo = {
  id: 0,
};

const MercadoAlvoCrm: React.FC = () => {
  const methods = useForm({ defaultValues: empty });

  const { id } = useParams<IParams>();
  const [loading, setLoading] = useState<boolean>(true);
  const [possuiMercadoAlvo, setPossuiMercadoAlvo] = useState(false);

  const mercadoAlvoActions = useMercadoAlvoActions();

  const [regioes, setRegioes] = useState<IMercadoAlvoRegiao[]>([]);

  const gruposActions = useGrupoActions();
  const usuariosActions = useUsuarioActions();
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);

  const faixaSalarialActions = useFaixaSalarialActions();
  const [faixaSalariais, setFaixaSalariais] = useState<IFaixaSalarial[]>([]);

  const configAreaActions = useConfigAreaActions();
  const [areas, setAreas] = useState<IConfigArea[]>([]);

  const configSubAreaActions = useConfigSubAreaActions();
  const [subAreas, setSubAreas] = useState<IConfigSubArea[] | undefined>(undefined);

  const configSegmentoActions = useConfigSegmentoActions();
  const [segmentos, setSegmento] = useState<IConfigSegmento[] | undefined>(undefined);

  const configSetorActions = useSetorActions();
  const [setores, setSetor] = useState<ISetor[] | undefined>(undefined);
  const [setoresFiltrados, setSetoresFiltrados] = useState<ISetor[]>([]);

  const nivelDeCargoActions = useNivelDeCargoActions();
  const [niveisDeCargos, setNiveisDeCargos] = useState<INivelDeCargo[]>([]);

  const idiomasActions = useIdiomaConfigActions();
  const [idiomas, setIdiomas] = useState<IIdioma[] | undefined>(undefined);

  const area = methods.watch('idMercadoAlvoAreas');
  const subArea = methods.watch('idMercadoAlvoSubAreas');
  const segmentosSelecionados = methods.watch('idMercadoAlvoSegmentos');
  const setor = methods.watch('idMercadoAlvoSetores');
  const [selectedValues, setSelectedValues] = useState('');

  const handleCheckboxChange = (value: any) => {
    setSelectedValues((prev) => {
      const values = prev.split(';').filter((v) => v);
      if (values.includes(value)) {
        return values.filter((v) => v !== value).join(';');
      } else {
        return [...values, value].join(';');
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      mercadoAlvoActions
        .list(false, `?contatoId=${id}`)
        .then((resp: any) => {
          if (resp.length > 0) atualizarFormulario(resp[0]);

          setTimeout(() => {
            setLoading(false);
            setPossuiMercadoAlvo(true);
          }, 1000);
        })
        .catch((err: any) => {
          setLoading(false);
          setPossuiMercadoAlvo(false);
        });
    }, 1000);

    configAreaActions
      .list()
      .then((resp: IConfigArea[]) => {
        resp = resp.sort((a, b) => (a.descricao || '').localeCompare(b.descricao || ''))
        setAreas(resp);
      })
      .catch((err: any) => toast.warn(err));

    configSubAreaActions
      .list()
      .then((resp: IConfigSubArea[]) => {
        resp = resp.sort((a, b) => (a.descricao || '').localeCompare(b.descricao || ''))
        setSubAreas(resp)
      })
      .catch((err: any) => toast.warn(err));

    configSegmentoActions
      .list()
      .then((resp: IConfigSegmento[]) => {
        resp = resp.sort((a, b) => (a.descricao || '').localeCompare(b.descricao || ''))
        setSegmento(resp)
      })
      .catch((err: any) => toast.warn(err));

    faixaSalarialActions
      .list()
      .then((resp: IFaixaSalarial[]) => {
        resp = resp.sort((a, b) => (a.nome || '').localeCompare(b.nome || ''))
        setFaixaSalariais(resp)
      })
      .catch((err: any) => toast.warn(err));

    configSetorActions
      .list()
      .then((resp: ISetor[]) => {
        resp = resp.sort((a, b) => (a.descricao || '').localeCompare(b.descricao || ''))
        setSetor(resp);
        setSetoresFiltrados(resp);

      })
      .catch((err: any) => toast.warn(err));

    nivelDeCargoActions
      .list()
      .then((resp: INivelDeCargo[]) => {
        resp = resp.sort((a, b) => (a.descricao || '').localeCompare(b.descricao || ''))
        setNiveisDeCargos(resp)
      })
      .catch((err: any) => toast.warn(err));

    idiomasActions
      .list()
      .then((resp: IIdioma[]) => {
        resp = resp.sort((a, b) => (a.nome || '').localeCompare(b.nome || ''))
        setIdiomas(resp);
      })
      .catch((err: any) => toast.warn(err));

    gruposActions
      .list()
      .then((resp: any) => {
        const grupo = resp.find((g: any) => g.nome == 'Mesa de Mercado');
        usuariosActions
          .buscarUsuarios(false, grupo?.id)
          .then((resp: any) => setUsuarios(resp.resultado))
          .catch((err: any) => toast.warn(err));
      });
  }, []);

  useEffect(() => {
    if (!area || area.length <= 0) return;

    const areasSelecionadas = areas?.filter((f) => area.includes(f.id!));
    const subAreasComDuplicatas = areasSelecionadas?.flatMap((area) => area.idSubAreas);
    const subAreasUnicas = subAreasComDuplicatas ?
      Array.from(new Set(subAreasComDuplicatas.map(item => item?.id)))
        .map(id => subAreasComDuplicatas.find(item => item?.id === id))
        .filter(s => s != undefined) as IConfigSubArea[] :
      [];
    setSubAreas(subAreasUnicas);
    const novasSubAreasSelecionadas = subAreasUnicas?.filter((f) => subArea?.includes(f.id!));
    methods.setValue('idMercadoAlvoSubAreas', novasSubAreasSelecionadas?.map((m: any) => m.id));
  }, [area]);

  useEffect(() => {
    if (!segmentosSelecionados || segmentosSelecionados.length <= 0) return;
    const setoresFiltradosNew = setores?.filter((f) => segmentosSelecionados.includes(f.idSegmento!)) ?? [];
    setSetoresFiltrados(setoresFiltradosNew);
    const novosSetoresSelecionados = setoresFiltradosNew?.filter((f) => setor?.includes(f.id!));
    methods.setValue('idMercadoAlvoSetores', novosSetoresSelecionados?.map((m: any) => m.id));
  }, [segmentosSelecionados]);

  const onSubmit = async (data: IMercadoAlvo) => {
    setLoading(true);
    let idMercadoAlvo = 0;
    if (data.id > 0) idMercadoAlvo = data.id;

    data.contatoId = Number(id!);
    data.mercadoAlvoAreas = areas
      ?.filter((area) => data.idMercadoAlvoAreas!.includes(area.id!))
      .map((x) => {
        return { idArea: x.id!, idMercadoAlvo: idMercadoAlvo };
      });
    data.mercadoAlvoSubAreas = subAreas
      ?.filter((subArea) => data.idMercadoAlvoSubAreas!.includes(subArea.id!))
      .map((x) => {
        return { idSubArea: x.id!, idMercadoAlvo: idMercadoAlvo };
      });
    data.mercadoAlvoSegmentos = segmentos
      ?.filter((segmento) => data.idMercadoAlvoSegmentos!.includes(segmento.id!))
      .map((x) => {
        return { idSegmento: x.id!, idMercadoAlvo: idMercadoAlvo };
      });
    data.mercadoAlvoSetores = setores
      ?.filter((setor) => data.idMercadoAlvoSetores!.includes(setor.id!))
      .map((x) => {
        return { idSetor: x.id!, idMercadoAlvo: idMercadoAlvo };
      });
    data.mercadoAlvoNiveisCargos = niveisDeCargos
      ?.filter((nivel) => data.idMercadoAlvoNiveisCargos!.includes(nivel.id!))
      .map((x) => {
        return { idNivelCargo: x.id!, idMercadoAlvo: idMercadoAlvo };
      });
    data.mercadoAlvoIdiomas = idiomas
      ?.filter((idioma) => data.idMercadoAlvoIdiomas!.includes(idioma.id!))
      .map((x) => {
        return { idIdioma: x.id!, idMercadoAlvo: idMercadoAlvo };
      });
    data.mercadoAlvoAgentesMercados = usuarios
      ?.filter((usuario) => data.idMercadoAlvoAgentesMercados!.includes(usuario.id!))
      .map((x) => {
        return { idUsuario: x.id!, idMercadoAlvo: idMercadoAlvo };
      });

    if (data.mercadoAlvoRegioes && data.mercadoAlvoRegioes?.length) {
      data.mercadoAlvoRegioes = data.mercadoAlvoRegioes.map((regiao) => {
        return {
          pais: regiao.pais,
          estado: regiao.estado,
          cidade: regiao.cidade,
          tipo: regiao.tipo,
          idMercadoAlvo: idMercadoAlvo,
        };
      });
    }

    data.modeloTrabalho = selectedValues;

    if (data.id <= 0) {
      mercadoAlvoActions
        .create(data)
        .then((resp: IMercadoAlvo) => {
          atualizarFormulario(resp);
          toast.success('Salvo com sucesso !');
        })
        .catch((err: any) => toast.warn(err))
        .finally(() => setLoading(false));
    } else {
      mercadoAlvoActions
        .update(data.id, data)
        .then((resp: IMercadoAlvo) => {
          atualizarFormulario(resp);
          toast.success('Salvo com sucesso !');
        })
        .catch((err: any) => toast.warn(err))
        .finally(() => setLoading(false));
    }
  };

  const atualizarFormulario = (result: any) => {
    setRegioes(result.mercadoAlvoRegioes);
    result.idMercadoAlvoAreas = result.mercadoAlvoAreas.map((x: any) => x.idArea);
    result.idMercadoAlvoSubAreas = result.mercadoAlvoSubAreas.map((x: any) => x.idSubArea);
    result.idMercadoAlvoSegmentos = result.mercadoAlvoSegmentos.map((x: any) => x.idSegmento);
    result.idMercadoAlvoSetores = result.mercadoAlvoSetores.map((x: any) => x.idSetor);
    result.idMercadoAlvoNiveisCargos = result.mercadoAlvoNiveisCargos.map(
      (x: any) => x.idNivelCargo,
    );
    result.idMercadoAlvoAgentesMercados = result.mercadoAlvoAgentesMercados.map(
      (x: any) => x.idUsuario,
    );
    result.idMercadoAlvoIdiomas = result.mercadoAlvoIdiomas.map((x: any) => x.idIdioma);
    result.palavraChave = result.palavraChave;
    result.pretensaoSalarioId = result.pretensaoSalarioId;
    result.ultimoSalarioId = result.ultimoSalarioId;
    result.modeloTrabalho = result.modeloTrabalho;
    
    handleCheckboxChange(result.modeloTrabalho)
    methods.reset(result, { keepDefaultValues: true });
  };

  return (
    <>
      <FormProvider {...methods}>
        <FormStato id="formArea" onSubmit={methods.handleSubmit(onSubmit)}>
          {!loading && possuiMercadoAlvo ? (
            <Grid
              item
              xs={12}
              md={12}
              sm={12}
              sx={{
                height: '300px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                rowGap: '10px',
              }}
            >
              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="idMercadoAlvoAreas"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant="outlined">
                        {areas && (
                          <MultiSelect
                            opcoes={areas as IConfigArea[]}
                            setValor={onChange}
                            valor={value || []}
                            label="Area"
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="idMercadoAlvoSubAreas"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant="outlined">
                        <MultiSelect
                          opcoes={
                            subAreas as IConfigSubArea[] ?? []
                          }
                          setValor={onChange}
                          valor={value || []}
                          label="Sub Area"
                        />
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="idMercadoAlvoAgentesMercados"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant="outlined">
                        {usuarios && (
                          <MultiSelectNome
                            opcoes={usuarios as IUsuario[]}
                            setValor={onChange}
                            valor={value || []}
                            label="Agente de Mercado"
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="idMercadoAlvoSegmentos"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant="outlined">
                        {segmentos && (
                          <MultiSelect
                            opcoes={segmentos as IConfigSegmento[]}
                            setValor={onChange}
                            valor={value || []}
                            label="Segmentos"
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="idMercadoAlvoSetores"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant="outlined">
                        {setoresFiltrados && (
                          <MultiSelect
                            opcoes={setoresFiltrados as ISetor[]}
                            setValor={onChange}
                            valor={value || []}
                            label="Setor"
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="idMercadoAlvoNiveisCargos"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant="outlined">
                        {niveisDeCargos && (
                          <MultiSelect
                            opcoes={niveisDeCargos as INivelDeCargo[]}
                            setValor={onChange}
                            valor={value || []}
                            label="Nivel de Cargo"
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  {areas && (
                    <Controller
                      control={methods.control}
                      name="palavraChave"
                      render={({ field: { ref, ...field } }) => (
                        <FormControl fullWidth variant="outlined">
                          <TextFieldStato
                            {...field}
                            inputRef={ref}
                            value={field.value}
                            label="Palavra Chave"
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="pretensaoSalarioId"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        {faixaSalariais && (
                          <>
                            <InputLabel id="demo-simple-select-label">Pretensão/Salário</InputLabel>
                            <SelectStato {...field} required label="Pretensão/Salário">
                              {faixaSalariais.map((faixa: IFaixaSalarial, index: number) => (
                                <MenuItem value={faixa.id} key={index}>
                                  {faixa.nome}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                }}
              >
                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="ultimoSalarioId"
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        {faixaSalariais && (
                          <>
                            <InputLabel id="demo-simple-select-label">Último Salário</InputLabel>
                            <SelectStato {...field} required label="Último Salário">
                              {faixaSalariais.map((faixa: IFaixaSalarial, index: number) => (
                                <MenuItem value={faixa.id} key={index}>
                                  {faixa.nome}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6} sm={6} sx={{ padding: '5px' }}>
                  <Controller
                    control={methods.control}
                    name="idMercadoAlvoIdiomas"
                    rules={{ required: true }}
                    render={({ field: { ref, onChange, value, ...field } }) => (
                      <FormControl required fullWidth variant="outlined">
                        {idiomas && (
                          <MultiSelectNome
                            opcoes={idiomas as IIdioma[]}
                            setValor={onChange}
                            valor={value || []}
                            label="Idiomas"
                          />
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                sm={12}
                sx={{
                  display: 'flex',
                  padding: '5px',
                  flexWrap: 'wrap',
                  alignItems: 'flex-end',
                }}
              >
                <GridItemCadastro item xs={12} md={3}>
                  <Typography variant="button" display="block" gutterBottom>
                    Modelo de trabalho pretendido:
                  </Typography>
                </GridItemCadastro>

                <GridItemCadastro item xs={12} md={9}>
                  {areas && (
                    <Controller
                      control={methods.control}
                      name="modeloTrabalho"
                      rules={{ required: true }}
                      render={({ field }) => (
                        <RadioGroup
                          row
                          aria-labelledby="checkbox-group-label"
                          name="checkbox-group"
                        >
                          <FormControlLabel
                            label="Modelo Remoto"
                            control={
                              <Checkbox
                                checked={selectedValues.includes('modeloRemoto')}
                                onChange={() => handleCheckboxChange('modeloRemoto')}
                              />
                            }
                          />
                          <FormControlLabel
                            label="Modelo Híbrido"
                            control={
                              <Checkbox
                                checked={selectedValues.includes('modeloHibrido')}
                                onChange={() => handleCheckboxChange('modeloHibrido')}
                              />
                            }
                          />
                          <FormControlLabel
                            label="Modelo Presencial"
                            control={
                              <Checkbox
                                checked={selectedValues.includes('modeloPresencial')}
                                onChange={() => handleCheckboxChange('modeloPresencial')}
                              />
                            }
                          />
                        </RadioGroup>
                      )}
                    />
                  )}
                </GridItemCadastro>
              </Grid>

              <TableRegiao regioes={regioes} tipo={TipoRegiao.DISPONIBILIDADE} />
              <TableRegiao regioes={regioes} tipo={TipoRegiao.RESTRICAO} />

              <GridItemCadastroBtnSalvar item xs={11} md={11}>
                <ButtonStato
                  disabled={!methods.formState.isValid}
                  variant="contained"
                  type="submit"
                >
                  Salvar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          ) : (
            <>
              {!loading && !possuiMercadoAlvo ? (
                <h3>
                  Este contato não possui Mercado Alvo, verifique se ele possui a opção
                  "Relacionamento/Mercado" ativa na Comanda atual
                </h3>
              ) : (
                <CircularProgress
                  style={{
                    width: '60px',
                    height: '60px',
                    position: 'relative',
                    left: '50%',
                    top: '25%',
                  }}
                />
              )}
            </>
          )}
        </FormStato>
      </FormProvider>
    </>
  );
};

const GridCadastro = styled(GridContainerCenter)`
  width: 97% !important;
  margin-right: 0px !important;
  display: flex;
  justify-content: space-between !important;
  padding: 15px 0%;
`;

export const GridItemCadastro = styled(Grid)`
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
`;

export const Separador = styled(Grid)`
  padding: 3px;
`;

export const GridItemLinhaCadastro = styled(Grid)`
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between !important;
`;

const FormStato = styled.form`
  padding: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px !important;
  padding-bottom: 50px;
`;

export default MercadoAlvoCrm;
