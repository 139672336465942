import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IMapaDeClientes } from '../../../commons/genericTypes/mercadoAlvo';
import { history } from '../../../helpers/history';
import { useMapaDeClientesActions } from '../../../states/mesaDeMercado/mapaDeClientes/mapaDeClientes';
import { BasePage, Container } from '../../CRM/Empresa';
import { ButtonStato, GridTopPage, IconTituloStato } from '../../../commons/styleds';
import { Box, createTheme, Grid, ThemeProvider, Typography } from '@mui/material';
import { ptBR } from '@mui/x-data-grid/locales';
import { toast } from 'react-toastify';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'nome', headerName: 'Nome', width: 200 },
  {
    field: 'ultimaIndicacao',
    headerName: 'Última Indicação',
    width: 150,
    valueFormatter: (params: any) => (params ? new Date(params).toLocaleDateString() : '—'),
  },
  { field: 'qtdEnvios', headerName: 'Envios', width: 100 },
  { field: 'pretensaoSalario', headerName: 'Pretensão Salarial', width: 150 },
  { field: 'ultimoSalario', headerName: 'Último Salário', width: 150 },
  {
    field: 'areas',
    headerName: 'Áreas',
    width: 150,
    valueGetter: (params: any) => params.join(', '),
  },
  {
    field: 'subareas',
    headerName: 'Subáreas',
    width: 150,
    valueGetter: (params: any) => params.join(', '),
  },
  {
    field: 'agentesMercado',
    headerName: 'Agentes de Mercado',
    width: 200,
    valueGetter: (params: any) => params.join(', '),
  },
  {
    field: 'niveisCargo',
    headerName: 'Níveis de Cargo',
    width: 150,
    valueGetter: (params: any) => params.join(', '),
  },
  {
    field: 'idiomas',
    headerName: 'Idiomas',
    width: 150,
    valueGetter: (params: any) => params.join(', '),
  },
  {
    field: 'segmentos',
    headerName: 'Segmentos',
    width: 150,
    valueGetter: (params: any) => params.join(', '),
  },
  {
    field: 'setores',
    headerName: 'Setores',
    width: 150,
    valueGetter: (params: any) => params.join(', '),
  },
  {
    field: 'consultores',
    headerName: 'Consultores',
    width: 200,
    valueGetter: (params: any) => params.join(', '),
  },
  {
    field: 'actions',
    headerName: 'Ações',
    type: 'actions',
    width: 100,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<VisibilityIcon />}
        label="Ver Detalhes"
        onClick={() => history.push(`/crm/contatos/${params.id}`)}
        color="primary"
      />,
    ],
  },
];

const MapaDeClientes: React.FC = () => {
  const actions = useMapaDeClientesActions();
  const [loading, setLoading] = useState(false);
  const [mapasDeClientes, setMapasDeClientes] = useState<IMapaDeClientes[]>([]);

  useEffect(() => {
    actions
      .list(false, `?pagina=1&tamanhoPagina=10000`)
      .then((resp) => {
        setMapasDeClientes(resp.resultado.itens);
      })
      .catch((e) => toast.error(e))
      .finally(() => setLoading(false));
  }, []);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          slotProps={{
            tooltip: { title: 'Mostrar ou Ocultar Colunas' },
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Exportar CSV ou PDF' },
            button: { variant: 'outlined' },
          }}
          csvOptions={{
            fileName: 'MapaDeClientes',
            delimiter: ';',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant="h5">Mapa de Clientes</Typography>
        </Grid>
      </GridTopPage>
      <Container container>
        <Grid item xs={12}>
          <Box
            sx={{
              height: '65vh',
              minHeight: '500px',
              width: '100%',
            }}
          >
            <DataGrid
              getRowId={(row) => row?.id}
              rows={mapasDeClientes}
              columns={columns}
              pagination
              pageSizeOptions={[5, 10, 20]}
              loading={loading}
              getRowHeight={() => 'auto'}
              slots={{ toolbar: CustomToolbar }}
            />
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

export default MapaDeClientes;
