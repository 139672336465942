import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
} from '@mui/material';
import { ButtonStato, GridTopPage, SelectStato, TextFieldStato } from '../../../../commons/styleds';
import { styled } from 'styled-components';
import { BasePage, Container } from '..';
import HeaderPages from '../../../../components/Generics/HeaderPages';
import { useRecoilValue } from 'recoil';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  ModoEventoEnum,
  IEvento,
  type ITipoEvento,
  IEventoBu,
} from '../../../../commons/genericTypes';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useEffect, useState } from 'react';
import { useTipoEventoActions } from '../../../../states/conteudos/tipoEventos/tipoEvento.actions';
import { useEventoActions } from '../../../../states/conteudos/eventos/evento.actions';
import { toast } from 'react-toastify';
import { history } from '../../../../helpers/history';
import { useParams } from 'react-router-dom';
import type { IParams } from '../../../CRM/MiniBio/Endereco';
import {
  DateTimePicker,
  DesktopDatePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import EditorHtml from '../../../../components/EditorHtml/EditorHtml';
import { TipoConsultor } from '../../../../commons/enums';
import { a11yProps, CustomTabPanel } from '../../../CRM/MiniBio/accordionAndTabPanel';
import Inscricoes from '../Inscricoes';
import MultiSelect from '../../../../components/Generics/MultiSelect';

dayjs.extend(utc);
dayjs.extend(timezone);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const empty: IEvento = {
  id: 0,
  titulo: '',
  descricao: '',
  visivel: true,
  ativo: true,
  dtInicio: undefined,
  dtFim: undefined,
  idUsuario: 0,
  modoEvento: ModoEventoEnum.Online,
  limiteParticipante: undefined,
  palestrante: '',
  linkEvento: '',
  eventoBUs: [],
};

const DetalhesEvento: React.FC = () => {
  const { id } = useParams<IParams>();
  const novoCadastro = id === 'novo';
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const eventoActions = useEventoActions();
  const tipoEventoActions = useTipoEventoActions();
  const [tipoEventos, setTipoEventos] = useState<ITipoEvento[]>([]);
  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const opcoesBus = Object.values(TipoConsultor).map((bu) => ({
    eventoId: novoCadastro ? 0 : Number(id),
    bu,
  }));

  const dataInicio = methods.watch('dtInicio');
  const modoEvento = methods.watch('modoEvento');

  useEffect(() => {
    const getData = async () => {
      const respTipoEventos = await tipoEventoActions.list();
      setTipoEventos(respTipoEventos);
      if (!novoCadastro) {
        const resp = await eventoActions.get(Number(id));
        methods.reset(resp, { keepDefaultValues: true });
      }
      setLoading(false);
    };

    getData();
  }, [id]);

  useEffect(() => {
    if (modoEvento === ModoEventoEnum.Online) methods.setValue('limiteParticipante', undefined);
  }, [modoEvento]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmit = async (data: any) => {
    setLoadingSave(true);
    try {
      if (!novoCadastro) {
        await atualizarEvento(data);
      } else {
        await criarEvento(data);
      }
    } catch (error) {
    } finally {
      setLoadingSave(false);
    }
  };

  const atualizarEvento = async (data: any): Promise<void> => {
    try {
      const result = await eventoActions.update(data.id, data);
      toast.success('Atualizado com sucesso');
      history.push('/conteudo/eventos');
    } catch (error) {
      toast.error('Erro ao Atualizar!');
    }
  };

  const criarEvento = async (data: any): Promise<void> => {
    try {
      const result = await eventoActions.create(data);
      toast.success('Cadastro realizado com sucesso');
      history.push('/conteudo/eventos');
    } catch (error) {
      toast.error('Erro ao Cadastrar!');
    }
  };

  const handleChangeBus = (event: any) => {
    const {
      target: { value },
    } = event;
    const selecionadas = typeof value === 'string' ? value.split(',') : value;

    const novaLista: any[] = [];
    selecionadas.forEach((itemSelecionado: any) => {
      const existe = novaLista.find((item) => item.bu == itemSelecionado.bu);

      if (existe) {
        const index = novaLista.findIndex((item) => item.bu === itemSelecionado.bu);
        novaLista.splice(index, 1);
      } else {
        novaLista.push(itemSelecionado);
      }
    });

    methods.setValue('eventoBUs', novaLista);
  };

  return (
    <BasePage>
      <HeaderPages
        actionBack={true}
        arrowBackClick="/conteudo/eventos"
        icon="ballot_icon"
        title="Eventos"
      />

      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            margin: '0px 25px !important',
          }}
        >
          <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Informações do Evento" {...a11yProps(0)} />
            <Tab label="Inscrições" {...a11yProps(1)} disabled={novoCadastro} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Container container>
            <Grid2 size={{ xs: 12 }}>
              {!loading ? (
                <FormProvider {...methods}>
                  <FormStato id="formEvento" onSubmit={methods.handleSubmit(onSubmit)}>
                    <h3>{!novoCadastro ? `Atualizar - ID: ${id}` : 'Novo Cadastro'}</h3>

                    <Grid2 container spacing={2}>
                      <Grid2 size={{ xs: 6 }}>
                        <Controller
                          control={methods.control}
                          name="idTipoEvento"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">Tipo de Evento</InputLabel>
                              <SelectStato {...field} required label="Tipo de Evento">
                                {tipoEventos.map((tipoEvento: ITipoEvento, index: number) => (
                                  <MenuItem value={tipoEvento.id} key={index}>
                                    {tipoEvento.nome}
                                  </MenuItem>
                                ))}
                              </SelectStato>
                            </FormControl>
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6 }}>
                        <Controller
                          control={methods.control}
                          name="palestrante"
                          render={({ field: { ref, ...field } }) => (
                            <FormControl required fullWidth variant="outlined">
                              <FormInput
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                label="Palestrante"
                              />
                            </FormControl>
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 12 }}>
                        <Controller
                          control={methods.control}
                          name="titulo"
                          rules={{ required: true }}
                          render={({ field: { ref, ...field } }) => (
                            <FormControl required fullWidth variant="outlined">
                              <FormInput
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                label="Titulo"
                              />
                            </FormControl>
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, md: 4 }}>
                        <Controller
                          control={methods.control}
                          name="dtInicio"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                {...field}
                                value={field.value ? dayjs.utc(field.value) : null}
                                onChange={(newValue) => {
                                  field.onChange(
                                    newValue ? dayjs.utc(newValue).toISOString() : null,
                                  );
                                }}
                                label="Data Início"
                                format="DD/MM/YYYY HH:mm"
                                slotProps={{ textField: { required: true } }}
                                ampm={false}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, md: 4 }}>
                        <Controller
                          control={methods.control}
                          name="dtFim"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                {...field}
                                value={field.value ? dayjs.utc(field.value) : null}
                                onChange={(newValue) => {
                                  field.onChange(
                                    newValue ? dayjs.utc(newValue).toISOString() : null,
                                  );
                                }}
                                label="Data Fim"
                                format="DD/MM/YYYY HH:mm"
                                slotProps={{ textField: { required: true } }}
                                ampm={false}
                                minDateTime={dataInicio ? dayjs.utc(dataInicio) : undefined}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 6, md: 4 }}>
                        <Controller
                          control={methods.control}
                          name="eventoBUs"
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel id="select-bus-label">BUs</InputLabel>
                              <Select
                                {...field}
                                labelId="select-bus-label"
                                id="select-bus"
                                multiple
                                onChange={handleChangeBus}
                                input={<OutlinedInput label="BUs" />}
                                renderValue={(selected) => (
                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {field.value.map((value: any) => (
                                      <Chip key={value.bu} label={value.bu} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              >
                                {opcoesBus.map((bu) => (
                                  <MenuItem key={bu.bu} value={bu as any}>
                                    {bu.bu}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 8, md: 2 }}>
                        <Controller
                          control={methods.control}
                          name="modoEvento"
                          rules={{ required: true }}
                          render={({ field }) => (
                            <FormControl required fullWidth variant="outlined">
                              <InputLabel>Modo do Evento</InputLabel>
                              <SelectStato
                                {...field}
                                label="Modo do Evento"
                                value={field.value}
                                onChange={(e) => field.onChange(e.target.value)}
                              >
                                {Object.values(ModoEventoEnum).map((modo) => (
                                  <MenuItem key={modo} value={modo}>
                                    {modo}
                                  </MenuItem>
                                ))}
                              </SelectStato>
                            </FormControl>
                          )}
                        />
                      </Grid2>

                      {methods.watch('modoEvento') === ModoEventoEnum.Presencial && (
                        <Grid2 size={{ xs: 4, md: 3 }}>
                          <Controller
                            control={methods.control}
                            name="limiteParticipante"
                            rules={{
                              required: true,
                              min: { value: 1, message: 'Limite deve ser maior que 0' },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <FormControl required fullWidth variant="outlined">
                                <FormInput
                                  {...field}
                                  type="number"
                                  label="Limite de Participantes"
                                  error={!!error}
                                  helperText={error?.message}
                                  slotProps={{
                                    htmlInput: {
                                      min: 1,
                                    },
                                  }}
                                  value={field.value || ''}
                                  onChange={(e) => {
                                    const value =
                                      e.target.value === '' ? undefined : Number(e.target.value);
                                    field.onChange(value);
                                  }}
                                />
                              </FormControl>
                            )}
                          />
                        </Grid2>
                      )}

                      <Grid2 size={{ xs: 12, md: 7 }}>
                        <Controller
                          control={methods.control}
                          name="linkEvento"
                          render={({ field: { ref, ...field } }) => (
                            <FormControl required fullWidth variant="outlined">
                              <FormInput
                                {...field}
                                inputRef={ref}
                                value={field.value}
                                label="Link para Evento"
                              />
                            </FormControl>
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 12 }}>
                        <Controller
                          control={methods.control}
                          name="descricao"
                          rules={{ required: true }}
                          render={({ field: { ref, ...field } }) => (
                            <div>
                              <EditorHtml
                                setAsHtml={field.onChange}
                                initialHtml={field.value}
                                label={'Descrição'}
                              />
                            </div>
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 12, md: 4, sm: 4 }}>
                        <Controller
                          control={methods.control}
                          name="ativo"
                          render={({ field }) => (
                            <FormControlLabelStato
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="Ativo"
                            />
                          )}
                        />
                      </Grid2>

                      <Grid2 size={{ xs: 12, md: 4, sm: 4 }}>
                        <Controller
                          control={methods.control}
                          name="visivel"
                          render={({ field }) => (
                            <FormControlLabelStato
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                />
                              }
                              label="Visivel Portal Assessorado"
                            />
                          )}
                        />
                      </Grid2>

                      <Grid2
                        size={{ xs: 12 }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingTop: '10px',
                          marginBottom: '20px',
                        }}
                      >
                        <ButtonStato
                          type="submit"
                          variant="contained"
                          disabled={!methods.formState.isValid || loadingSave}
                        >
                          Salvar
                        </ButtonStato>
                      </Grid2>
                    </Grid2>
                  </FormStato>
                </FormProvider>
              ) : (
                <>
                  <CircularProgress
                    style={{
                      width: '60px',
                      height: '60px',
                      position: 'relative',
                      left: '50%',
                      top: '25%',
                    }}
                  />
                </>
              )}
            </Grid2>
          </Container>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Inscricoes />
        </CustomTabPanel>
      </Box>
    </BasePage>
  );
};

export const FormStato = styled.form``;

const FormInput = styled(TextFieldStato)``;

const FormControlLabelStato = styled(FormControlLabel)``;

export default DetalhesEvento;
