import { Route, Switch } from 'react-router-dom';
import Login from './pages/Login';
import Pagina404 from './pages/Pagina404';
import Home from './pages/Home';
import MenuBar from './components/Menu';
import MiniBio from './pages/CRM/MiniBio';
import LogStatoGo from './pages/LogStatoGo';
import LogSistema from './pages/LogSistema';
import Areas from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/Areas';
import ConfigSubArea from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/ConfigSubArea';
import Segmento from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/Segmentos';
import Setor from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/Setores';
import NivelDeCargo from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/NivelDeCargo';
import FaixaSalarial from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/FaixasSalariais';
import Idioma from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/Idiomas';
import TipoDeAcao from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/TiposDeAcoes';
import DisponibilidadeDeMudança from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/DisponibilidadeDeMudanças';
import Plano from './pages/FerramentasAdministrativas/Programas/Planos';
import Usuario from './pages/FerramentasAdministrativas/CadastroUsuarios/Usuarios';
import Grupos from './pages/FerramentasAdministrativas/CadastroUsuarios/Grupos';
import Comanda from './pages/CRM/Comanda';
import Etapa from './pages/FerramentasAdministrativas/Programas/Etapas';
import Contato from './pages/CRM/Contato';
import Status from './pages/FerramentasAdministrativas/Programas/Status';
import Metodologias from './pages/FerramentasAdministrativas/Programas/Metodologias';
import Prazos from './pages/FerramentasAdministrativas/Programas/Prazos';
import PlanosTemplate from './pages/FerramentasAdministrativas/Programas/PlanosTemplates';
import Relatorios from './pages/Relatorios';
import RelatorioTimeSheet from './pages/Relatorios/TimeSheet';
import Secoes from './pages/Conteudo/Secoes';
import TbAreas from './pages/Conteudo/TbAreas';
import Eventos from './pages/Conteudo/Eventos';
import Conteudo from './pages/Conteudo/Conteudo';
import Consultor from './pages/FerramentasAdministrativas/CadastroUsuarios/Consultores';
import TipoDeEvento from './pages/FerramentasAdministrativas/ConfiguracaoSistemas/TiposDeEvento';
import Empresa from './pages/CRM/Empresa';
import NovaEmpresa from './pages/CRM/Empresa/NovaEmpresa';
import DetalhesEmpresa from './pages/CRM/Empresa/DetalhesEmpresa';
import { PermissionsProvider } from './components/PermissionsProvider';
import EmpresasRelacionamento from './pages/MesaMercado/EmpresasRelacionamento';
import EmpresaRelacionamento from './pages/MesaMercado/EmpresasRelacionamento/Detalhes';
import VagasFollowUp from './pages/MesaMercado/FollowUp';
import FollowUpDetalhes from './pages/MesaMercado/FollowUp/Detalhes';
import SolicitarRedefinicaoSenha from './pages/RedefinirSenha/SolicitarRedefinicaoSenha';
import RedefinicaoSenha from './pages/RedefinirSenha/RedefinicaoSenha';
import MapaDeClientes from './pages/MesaMercado/MapaDeClientes';
import DetalhesEvento from './pages/Conteudo/Eventos/Detalhes';
import ExportacaoGeral from './pages/Relatorios/ExportacaoGeral';

const WithPermissions = (Component: any, modulo: string) => {
  return function ComponentWithPermissions(props: any) {
    return (
      <PermissionsProvider modulo={modulo}>
        <Component {...props} />
      </PermissionsProvider>
    );
  };
};

function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/redefinir-senha" component={SolicitarRedefinicaoSenha} />
      <Route exact path="/redefinir-senha/:id" component={RedefinicaoSenha} />

      <MenuBar>
        <ConfiguracoesSistemaRoutes />
        <UsuariosRoutes />
        <ProgramasRoutes />
        <MesaDeMercadoRoutes />
        <CrmRoutes />
        <ConteudoRoutes />
        <LogsRoutes />
        <RelatoriosRoutes />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
        </Switch>
      </MenuBar>
      <Route exact component={Pagina404} />
    </Switch>
  );
}

export const RelatoriosRoutes = () => (
  <Switch>
    <Route exact path="/relatorios" component={Relatorios} />
    <Route exact path="/relatorios/timesheet" component={RelatorioTimeSheet} />
    <Route exact path="/relatorios/exportacao-geral" component={ExportacaoGeral} />
  </Switch>
);

export const UsuariosRoutes = () => (
  <Switch>
    <Route
      exact
      path="/usuarios/usuarios"
      component={WithPermissions(Usuario, 'cadastro de usuarios')}
    />
    <Route
      exact
      path="/usuarios/consultores"
      component={WithPermissions(Consultor, 'cadastro de usuarios')}
    />
    <Route
      exact
      path="/usuarios/grupos"
      component={WithPermissions(Grupos, 'cadastro de usuarios')}
    />
  </Switch>
);

export const ProgramasRoutes = () => (
  <Switch>
    <Route
      exact
      path="/programas/planos"
      component={WithPermissions(PlanosTemplate, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/programas/etapas"
      component={WithPermissions(Etapa, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/programas/metodologias"
      component={WithPermissions(Metodologias, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/programas/prazos"
      component={WithPermissions(Prazos, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/programas/status"
      component={WithPermissions(Status, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/programas/planos-templates"
      component={WithPermissions(PlanosTemplate, 'configuracoes sistema')}
    />
  </Switch>
);

export const ConfiguracoesSistemaRoutes = () => (
  <Switch>
    <Route
      exact
      path="/configuracoes-sistema/areas"
      component={WithPermissions(Areas, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/config-sub-areas"
      component={WithPermissions(ConfigSubArea, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/segmentos"
      component={WithPermissions(Segmento, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/setores"
      component={WithPermissions(Setor, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/niveis-de-cargos"
      component={WithPermissions(NivelDeCargo, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/faixas-salariais"
      component={WithPermissions(FaixaSalarial, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/idiomas"
      component={WithPermissions(Idioma, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/tipo-de-acao"
      component={WithPermissions(TipoDeAcao, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/tipo-de-evento"
      component={WithPermissions(TipoDeEvento, 'configuracoes sistema')}
    />
    <Route
      exact
      path="/configuracoes-sistema/disponibilidade-de-mudanca"
      component={WithPermissions(DisponibilidadeDeMudança, 'configuracoes sistema')}
    />
  </Switch>
);

export const MesaDeMercadoRoutes = () => (
  <Switch>
    <Route
      exact
      path="/empresas-relacionamento"
      component={WithPermissions(EmpresasRelacionamento, 'mesa de mercado')}
    />
    <Route
      exact
      path="/empresas-relacionamento/:id"
      component={WithPermissions(EmpresaRelacionamento, 'mesa de mercado')}
    />
    <Route exact path="/follow-up" component={WithPermissions(VagasFollowUp, 'mesa de mercado')} />
    <Route
      exact
      path="/follow-up/:id"
      component={WithPermissions(FollowUpDetalhes, 'mesa de mercado')}
    />
    <Route
      exact
      path="/mapa-de-clientes"
      component={WithPermissions(MapaDeClientes, 'mesa de mercado')}
    />
  </Switch>
);

export const CrmRoutes = () => (
  <Switch>
    <Route exact path="/crm/contatos" component={WithPermissions(Contato, 'crm')} />
    <Route exact path="/crm/empresa" component={WithPermissions(Empresa, 'crm')} />
    <Route exact path="/crm/empresa/nova" component={WithPermissions(NovaEmpresa, 'crm')} />
    <Route exact path="/crm/empresa/:id" component={WithPermissions(DetalhesEmpresa, 'crm')} />
    <Route exact path="/crm/programas/novo" component={WithPermissions(Comanda, 'crm')} />
    <Route exact path="/crm/contatos/:id" component={WithPermissions(MiniBio, 'crm')} />
  </Switch>
);

export const ConteudoRoutes = () => (
  <Switch>
    <Route exact path="/conteudo" component={WithPermissions(Conteudo, 'conteudo')} />
    <Route exact path="/conteudo/areas" component={WithPermissions(TbAreas, 'conteudo')} />
    <Route exact path="/conteudo/secoes" component={WithPermissions(Secoes, 'conteudo')} />
    <Route exact path="/conteudo/conteudo" component={WithPermissions(Conteudo, 'conteudo')} />
    <Route exact path="/conteudo/eventos" component={WithPermissions(Eventos, 'conteudo')} />
    <Route
      exact
      path="/conteudo/eventos/:id"
      component={WithPermissions(DetalhesEvento, 'conteudo')}
    />
  </Switch>
);

export const LogsRoutes = () => (
  <Switch>
    <Route exact path="/log-sistema" component={WithPermissions(LogSistema, 'log do sistema')} />
    <Route exact path="/log-statoGo" component={WithPermissions(LogStatoGo, 'log do statogo')} />
  </Switch>
);

export default Routes;
